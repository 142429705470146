import React, { useEffect, useState } from "react";
import NavbarLayout from "../components/NavbarLayout";
import Roleslist from "../components/Roles/Roleslist";
import { useLocation } from "react-router-dom";
import AddRoles from "../components/Roles/AddRoles";
import { showToast } from "../features/Toaster/toastslice";
import { useAppDispatch } from "../app/hooks";
import { commonGetService, getCookie } from "../utils/properties";
import { RolesData } from "./ManagerList";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const roleContext = React.createContext<RolesData[] | null>(null);

const Roles = () => {
  const location = useLocation();
  let path = location.pathname.split("/");
  const [spinner, setSpinner] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [rolesList, setRolesList] = useState<RolesData[]>([]);

  useEffect(() => {
    // checkCookie();
    getRolesList();
  }, [location?.state?.reload]);

  // const checkCookie = () => {
  //   if (getCookie() === undefined) {
  //     navigate("/");
  //   }
  // };

  const getRolesList = () => {
    commonGetService(`/api/1.0/role/all`).then((res) => {
      if (res.status === 200) {
        setRolesList(res?.data);
        setSpinner(false);
      } else {
        dispatch(
          showToast({
            message: "Something went wrong",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  return (
    <div className="roles-parent-page">
      <NavbarLayout>
        {path[1] === "roles" && (
          <div
            className={`${rolesList.length ? "" : "no-data-height"
              } roles-container `}
          >
            {!spinner ? (
              <roleContext.Provider value={rolesList}>
                <Roleslist />
              </roleContext.Provider>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100 crt-loader h-20">
                <img src="/images/loader/content_loader.gif" />
                {/* <Spinner animation="border" /> */}
              </div>
            )}
          </div>
        )}
        {(path[1] === "addroles" ||
          path[1] === "editroles" ||
          path[1] === "viewroles") && (
            <div
              className={`${rolesList.length ? "" : "no-data-height"
                } add-roles-container `}
            >
              <AddRoles />
            </div>
          )}
      </NavbarLayout>
    </div>
  );
};

export default Roles;
