import React, { useState, useEffect, useRef } from 'react';
import NavbarLayout from '../components/NavbarLayout';
import '../styles/Managers/managerList.scss'
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setEditUserDetails, setIsEdit, setModalShow } from '../features/addManager/addManagerSlice';
import DeletePopup from '../components/AddManager/DeletePopup';
import { commonGetService, commonPostservice, commonDeleteService, commonPutService, getCookie } from '../utils/properties';
import { showToast } from '../features/Toaster/toastslice';
import { useLocation, useNavigate } from 'react-router';
import { AccessInfo, AccessDetails } from './Dashboard';

export interface ManagerData {
    // id: string,
    // image: string,
    // email: string,
    // department: string,
    // designation: string,
    // role: string,
    // address: string,
    // employee_id: number,
    // employee_code: string,r
    // employee_name: string,
    // reason: string,
    // device_id: string,
    // office_id: number,
    // office_name: string,
    // office_time: string,
    // map_link: string,
    // created_date: string,
    // isDropShow?: boolean,
    // xeroxRole?: string,
    // isEdit?: boolean

    status: string,
    id: string,
    email: string,
    password: string,
    role_id: string,
    sr_no: string,
    created_date: string,
    updated_date?: string,
    role: string,
    employee_code?: string,
    editable?: boolean

    // filterId?: number | undefined
}

export interface FeatureAccess {
    full_access: boolean,
    create_access: boolean,
    view_access: boolean,
    edit_access: boolean,
    delete_access: boolean
}

export interface RoleFeatureCategoryMapping {
    id?: string,
    feature_category: {
        id?: string,
        category_name: string
    },
    // feature_access: FeatureAccess
}

export interface RolePermission {
    id?: string,
    page_name: string,
    role_permission_enabled: boolean,
    role_feature_category_mapping: RoleFeatureCategoryMapping[]
}
export interface RolesData {
    id: string,
    status: boolean,
    description: string,
    role_name: string,
    created_date: string,
    modified_date: string,
    department_ids: string[],
    feature_id: string[]
}

const ManagerList = () => {
    const [managerList, setManagerList] = useState<ManagerData[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSave, setIsSave] = useState<boolean>(false);
    const [currentAdminRole, setCurrentAdminRole] = useState<string>("");
    const [accessInfo, setAccessInfo] = useState<AccessInfo>({});
    // const [rolesList, setRolesList] = useState<RolesData[]>([]);
    // const [selectedManager, setSelectedManager] = useState<ManagerData | null>(null);
    // const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);



    const targetRef = useRef<HTMLDivElement | null>(null);
    const addManager = useAppSelector((state) => state.addManager)
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const currentUserId = location.state?.state;


    useEffect(() => {
        // checkCookie();
        getManagerList();

        // window.addEventListener("click", handleClickOutside);    

        // return () => {
        //     window.removeEventListener("click", handleClickOutside);
        // };
    }, [addManager?.isManagerCreate])

    // const checkCookie = () => {
    //     if (getCookie() === undefined) {
    //       navigate("/");
    //     }
    //   };

    useEffect(() => {
        if (localStorage.getItem("roleDetails")) {
            let roleDetails = JSON.parse(localStorage.getItem("roleDetails") as string);
            let accessInfo: AccessInfo = {}
            roleDetails?.features?.manager?.features?.map((res: AccessDetails) => {
                accessInfo[res.name] = res?.status;
            })
            setAccessInfo(accessInfo);
        }
    }, [])

    // Get All the Manager Details in the organization
    const getManagerList = () => {
        setIsLoading(true);
        commonGetService(`/api/1.0/manager/list/all`).then(res => {
            setIsLoading(false);
            // getRolesList();
            if (res.status === 200) {
                // res?.data?.map((val: ManagerData) => {
                //     val.isDropShow = false;
                //     val.xeroxRole = val?.role;
                //     val.isEdit = false;
                //     val.filterId = Math.floor(Math.random() * 90000) + 10000;
                // })

                setManagerList(res?.data);

            }
            else {
                dispatch(
                    showToast({
                        message: "Something went wrong in API",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        })
    }




    // const getManagerList=async ()=>{
    //     setIsLoading(true);
    //     try {
    //     let managerApiData=await commonGetService('/api/1.0/manager/list/all')
    //     let resultManagerData=await managerApiData.data;

    //     if(managerApiData.res===200){
    //         resultManagerData.map((val:ManagerData)=>{
    //             val.isDropShow=false;

    //         })
    //     }



    //     } catch (error) {

    //     }

    // }

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, roleId: string, role: string) => {
        const params = {
            id: roleId,
            status: e.target.checked ? "active" : "inactive"

        }

        setIsSave(true);
        commonPutService(`/api/1.0/manager/toggle`, params).then(res => {
            setIsSave(false);
            if (res.status === 200) {
                getManagerList();
            }
          
            else  {
                dispatch(
                    showToast({
                        message: "Something went wrong in API",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        })
    }

    const handleEdit = (data: ManagerData) => {
        dispatch(setEditUserDetails(data));
        dispatch(setIsEdit(true));
        dispatch(setModalShow(true))
    }

    return (
        <NavbarLayout>
            <div className='mgr-container'>
                <div className='list-mgr-contaniner d-flex flex-column'>
                    <div className='add-mgr d-flex flex-row justify-content-between w-100'>
                        <div className='d-flex flex-column'>
                            <h3>List of Managers</h3>
                            <div className='search-bar mt-4'>
                                <Form.Control type='text' placeholder='Search using Name, ID and Department' value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                <Image
                                    src="/images/dashboard/grey_search.svg"
                                    alt="Search_Icon"
                                    className="position-absolute"
                                />
                            </div>
                        </div>
                        {accessInfo["Add Manager"] &&
                            <div className='btn-cont'>
                                <Button onClick={() => dispatch(setModalShow(true))}>+ Add Manager</Button>
                            </div>
                        }
                    </div>

                    <div className='mgr-list w-100 common-scroll mx-auto'>
                        <table className='w-100'>
                            <thead>
                                <tr>
                                    {/* <th>Emp ID</th>
                                    <th>Employee</th>
                                    <th>Designation</th>
                                    <th>Department</th>
                                    <th>Floor</th>
                                    <th>Created Date</th> */}
                                    <th>Email-Id</th>
                                    <th>Role</th>
                                    {(accessInfo["Update Manager"] || accessInfo["Activate Manager"]) &&
                                        <th>Action</th>
                                    }
                                </tr>
                            </thead>
                            <tbody className='common-scroll'>
                                {isLoading ?
                                    <tr>
                                        <td className='spin-col' colSpan={8}>
                                            <div className="d-flex justify-content-center align-items-center h-100 w-100 crt-loader">
                                                <img src="/images/loader/content_loader.gif" />
                                                {/* <Spinner animation="border" /> */}
                                            </div>
                                        </td>
                                    </tr> : managerList?.length ? managerList?.filter((val) => {
                                        if (searchText.trim() === "") {
                                            return val;
                                        }
                                        else if (val?.email?.trim().toLowerCase().includes(searchText?.trim().toLowerCase()) ||
                                            val?.role?.trim().toLowerCase().includes(searchText?.trim().toLowerCase())) {
                                            return val;
                                        }
                                    })?.map((res, ind) => (
                                        <tr key={ind}>



                                            <td>
                                                <div>{res?.email}</div>
                                            </td>
                                            <td>
                                                <div className='text-capitalize'>{res?.role}</div>
                                            </td>
                                            {(accessInfo["Update Manager"] || accessInfo["Activate Manager"]) &&
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center edit-grp'>
                                                        {accessInfo["Update Manager"] &&
                                                            <div
                                                                className="d-flex justify-content-center align-items-center edit-grp"
                                                                onClick={() => { res?.editable && handleEdit(res) }}
                                                            >
                                                                <Image src="/images/roles/manager_edit_icon.svg" alt="edit" />
                                                            </div>
                                                        }
                                                        {accessInfo["Activate Manager"] &&
                                                            <Form.Check
                                                                type="switch"
                                                                checked={res?.status === "active"}
                                                                id={res?.email}
                                                                onChange={(e) => {
                                                                    handleOnChange(e, res?.id, res?.role)


                                                                }}
                                                                disabled={res.email === currentUserId || !res?.editable ? true : false}
                                                            />
                                                        }
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    )) :
                                        <tr>
                                            <td className='no-data' colSpan={8}>
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <Image
                                                        src="/images/dashboard/user_icon.png"
                                                        alt="No Data Found"
                                                    />
                                                    <p className="m-0">No users to show</p>
                                                </div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* {managerList?.filter(res => res.isEdit === true)?.length > 0 &&
                        <div className='save-btn ms-auto'>
                            <Button disabled={isSave} onClick={() => handleSave()}>Save Changes</Button>
                        </div>
                    } */}
                </div>
            </div>

            {/* Delete Modal Component */}
            {/* {deleteModalShow &&
                <DeletePopup modalShow={deleteModalShow} handleModal={handleDelete} />
            } */}

        </NavbarLayout>
    );
};

export default ManagerList;