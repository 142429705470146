import React, { useState, useEffect } from 'react'
import NavbarLayout from '../components/NavbarLayout'
import "../styles/settings.scss"
import { Tabs, TabsProps } from 'antd';
import General from '../components/Settings/General';
import Tracking from '../components/Settings/Tracking';
import { AccessInfo, AccessDetails } from './Dashboard';
const Settings = () => {

    const [refresh, setRefresh] = useState(false)
    const [refresh1, setRefresh1] = useState(false)
    const [generalRole, setGeneralRole] = useState("")
    const [activeKey, setActiveKey] = useState<string>('1')
    const [accessInfo, setAccessInfo] = useState<AccessInfo>({});


    const handleGeneralRole = (data: string) => {
        setGeneralRole(data);
    };

    const both_general_and_tracking = accessInfo["General Setting"] && accessInfo["Tracking And Monitoring"];
    const general_alone = accessInfo["General Setting"] && !accessInfo["Tracking And Monitoring"];
    const tracking_alone = !accessInfo["General Setting"] && accessInfo["Tracking And Monitoring"];


    const items: TabsProps['items'] = both_general_and_tracking ? [
        {
            key: '1',
            label: 'General',
            children: <General refresh={refresh} adminData={handleGeneralRole} />,
        },
        {
            key: '2',
            label: 'Tracking and Monitoring',
            children: <Tracking refresh1={refresh1} currentRole={generalRole} />,
        }

    ] : general_alone ? [
        {
            key: '1',
            label: 'General',
            children: <General refresh={refresh} adminData={handleGeneralRole} />,
        }
    ] : [
        {
            key: '2',
            label: 'Tracking and Monitoring',
            children: <Tracking refresh1={refresh1} currentRole={generalRole} />,
        }
    ];
    useEffect(() => {
        const savedActiveKey = localStorage.getItem('activeTabKey');
        if (savedActiveKey) {
            setActiveKey(savedActiveKey);
        }
        return () => {
            localStorage.removeItem('activeTabKey');
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem("roleDetails")) {
            let roleDetails = JSON.parse(localStorage.getItem("roleDetails") as string);
            let accessInfo: AccessInfo = {}
            roleDetails?.features?.setting?.features?.map((res: AccessDetails) => {
                accessInfo[res.name] = res?.status;
            })
            setActiveKey((accessInfo["General Setting"] && accessInfo["Tracking And Monitoring"]) ||
                (accessInfo["General Setting"] && !accessInfo["Tracking And Monitoring"]) ? '1' :
                !(accessInfo["General Setting"] && accessInfo["Tracking And Monitoring"]) ? '2' : '1')
            setAccessInfo(accessInfo);
        }
    }, [])

    const onChange = (key: string) => {
        setActiveKey(key);
        localStorage.setItem('activeTabKey', key);
        if (key === "1") setRefresh(!refresh)
        if (key === "2") setRefresh1(!refresh1)
    };

    return (
        <div className='settings-page-parent'>
            <NavbarLayout >
                <div className="data-container1 ">
                    <div className='settings-page-wrapper '>
                        <h4>Settings</h4>
                        <Tabs activeKey={activeKey} items={items} onChange={onChange} />
                    </div>
                </div>
            </NavbarLayout>
        </div>
    )
}

export default Settings