import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ManagerData as EditUserDetails } from "../../pages/ManagerList";

interface InitialState {
    modalShow: boolean,
    isManagerCreate: boolean,
    managerDropShow: boolean,
    isEdit: boolean,
    editUserDetails: EditUserDetails,
    moreDropShow: boolean
}

const initialState: InitialState = {
    modalShow: false,
    isManagerCreate: false,
    managerDropShow: false,
    isEdit: false,
    editUserDetails: {
        status: "",
        id: "",
        email: "",
        password: "",
        role_id: "",
        sr_no: "",
        created_date: "",
        updated_date: "",
        role: "",
        employee_code: ""
    },
    moreDropShow: false
}

const addManagerSlice = createSlice({
    name: 'addManager',
    initialState,
    reducers: {
        setModalShow: (state, action: PayloadAction<boolean>) => {
            state.modalShow = action.payload;
        },
        setIsManagerCreate: (state, action: PayloadAction<boolean>) => {
            state.isManagerCreate = action.payload
        },
        setManagerDropShow: (state, action: PayloadAction<boolean>) => {
            state.managerDropShow = action.payload
        },
        setIsEdit: (state, action: PayloadAction<boolean>) => {
            state.isEdit = action.payload
        },
        setEditUserDetails: (state, action: PayloadAction<EditUserDetails>) => {
            state.editUserDetails = action.payload
        },
        setMoreDropShow: (state, action: PayloadAction<boolean>) => {
            state.moreDropShow = action.payload;
        }
    }
})

export const { setModalShow, setIsManagerCreate, setManagerDropShow, setIsEdit, setEditUserDetails, setMoreDropShow } = addManagerSlice.actions
export default addManagerSlice.reducer