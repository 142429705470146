import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import moment from "moment";
import { commonGetService } from "../../utils/properties";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import "../../styles/timeline.scss";
import { showToast } from "../../features/Toaster/toastslice";

interface Activity {
  start_time: number;
  end_time: number;
  app: string | null;
  category: string;
  start_time_am_pm: string;
  end_time_am_pm: string;
  duration: string;
  durationInSec: number;
}

interface TimeLine extends Activity {
  start: number;
  end: number;
}

interface DailyData {
  date: string;
  total_worked_time: string;
  idle_time: string;
  start_time_in_am_pm: string;
  end_time_in_am_pm: string;
  start_time: number[];
  end_time: number[];
  activities: Activity[] | null;
  timeLine?: TimeLine[][] | null;
}

const TimeLineTable = () => {
  const [chartLoading, setIsChartLoading] = useState(true);
  const [timeLineData, setTimeLineData] = useState<DailyData[]>([]);

  const filterData = useAppSelector((state) => state.filterData);
  const dispatch = useAppDispatch();
  const { startDate, endDate, empDeviceId } = filterData; // Destructuring the filterData state value

  const controllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    getTimelineData();
    setIsChartLoading(true);

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      setTimeLineData([]);
    };
  }, [filterData]);

  const getTimelineData = () => {

    if (controllerRef.current) {
      controllerRef.current.abort();

    }

    // Create a new AbortController for the new request
    controllerRef.current = new AbortController();
    const { signal } = controllerRef.current;
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let toDate = moment(endDate).format("YYYY-MM-DD");

    commonGetService(
      `/api/1.0/dashboard/user/data?deviceId=${empDeviceId}&startDate=${fromDate}&endDate=${toDate}`,
      signal
    ).then((res) => {
      setIsChartLoading(false);
      if (res.status === 200) {
        let reversedData = res.data?.reverse();
        reversedData?.forEach((item: DailyData) => {
          item.timeLine = [];
          const timeline = Array(12).fill([]);

          item?.activities?.forEach((act) => {
            const start = moment(act?.start_time_am_pm, "hh:mm:ss A");
            const end = moment(act?.end_time_am_pm, "hh:mm:ss A");
            const sixAM = moment("06:00:00 AM", "hh:mm:ss A");
            let startHour = 0;
            let endHour = 0;
            // Check if the start time is before 6 AM
            // + 18 is to move the bars to am section 9 cells before it so 9*2 = 18
            if (start.isBefore(sixAM)) {
              startHour = Math.floor((start.hours() + 18) / 2);
              endHour = Math.floor((end.hours() + 18) / 2);
            } else {
              startHour = Math.floor((start.hours() - 6) / 2);
              endHour = Math.floor((end.hours() - 6) / 2);
            }
            for (let hour = startHour; hour <= endHour; hour++) {
              if (hour === startHour && hour === endHour) {
                const startSeconds =
                  moment(start).hour() % 2 === 0
                    ? start.diff(moment(start).startOf("hour"), "seconds")
                    : start.diff(
                      start.clone().subtract(1, "hours").startOf("hour"),
                      "seconds"
                    );

                const endSeconds =
                  moment(end).hour() % 2 === 0
                    ? end.diff(moment(end).startOf("hour"), "seconds")
                    : end.diff(
                      end.clone().subtract(1, "hours").startOf("hour"),
                      "seconds"
                    );

                timeline[hour] = [
                  ...timeline[hour],
                  {
                    start: startSeconds / 7200,
                    end: endSeconds / 7200,
                    start_time: act?.start_time,
                    end_time: act?.end_time,
                    app: act?.app,
                    category: act?.category,
                    start_time_am_pm: act?.start_time_am_pm,
                    end_time_am_pm: act?.end_time_am_pm,
                    duration: act?.duration,
                    durationInSec: act?.durationInSec,
                  },
                ];
              } else if (hour === startHour) {
                const startSeconds =
                  moment(start).hour() % 2 === 0
                    ? start.diff(moment(start).startOf("hour"), "seconds")
                    : start.diff(
                      start.clone().subtract(1, "hours").startOf("hour"),
                      "seconds"
                    );

                timeline[hour] = [
                  ...timeline[hour],
                  {
                    start: startSeconds / 7200,
                    end: 1,
                    start_time: act?.start_time,
                    end_time: act?.end_time,
                    app: act?.app,
                    category: act?.category,
                    start_time_am_pm: act?.start_time_am_pm,
                    end_time_am_pm: act?.end_time_am_pm,
                    duration: act?.duration,
                    durationInSec: act?.durationInSec,
                  },
                ];
              } else if (hour === endHour) {
                const endSeconds =
                  moment(end).hour() % 2 === 0
                    ? end.diff(moment(end).startOf("hour"), "seconds")
                    : end.diff(
                      end.clone().subtract(1, "hours").startOf("hour"),
                      "seconds"
                    );

                timeline[hour] = [
                  ...timeline[hour],
                  {
                    start: 0,
                    end: endSeconds / 7200,
                    start_time: act?.start_time,
                    end_time: act?.end_time,
                    app: act?.app,
                    category: act?.category,
                    start_time_am_pm: act?.start_time_am_pm,
                    end_time_am_pm: act?.end_time_am_pm,
                    duration: act?.duration,
                    durationInSec: act?.durationInSec,
                  },
                ];
              } else {
                timeline[hour] = [
                  ...timeline[hour],
                  {
                    start: 0,
                    end: 1,
                    start_time: act?.start_time,
                    end_time: act?.end_time,
                    app: act?.app,
                    category: act?.category,
                    start_time_am_pm: act?.start_time_am_pm,
                    end_time_am_pm: act?.end_time_am_pm,
                    duration: act?.duration,
                    durationInSec: act?.durationInSec,
                  },
                ];
              }
            }
          });

          item.timeLine = timeline;
        });

        setTimeLineData(reversedData);
      } else {
        setIsChartLoading(true);
        // dispatch(
        //   showToast({
        //     message: "Something went wrong in API.",
        //     status: "retry",
        //     visible: true,
        //   })
        // );
      }
    });
  };

  return (
    <div className="d-flex flex-column timeline-container">
      <div className="py-3 px-0">
        <h4>Time lines</h4>
      </div>
      {chartLoading ? (
        <div className="d-flex justify-content-center align-items-center crt-loader spin-h">
          <img src="/images/loader/content_loader.gif" />
          {/* <Spinner animation="border" /> */}
        </div>
      ) : (
        <div className="time-line-chart w-100 common-scroll ">
          <table className="w-100">
            <thead>
              <tr>
                <th>Date</th>
                <th>Worked Time</th>
                <th>Idle Time</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>8 AM</th>
                <th>10 AM</th>
                <th>12 PM</th>
                <th>2 PM</th>
                <th>4 PM</th>
                <th>6 PM</th>
                <th>8 PM</th>
                <th>10 PM</th>
                <th>12 AM</th>
                <th>2 AM</th>
                <th>4 AM</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="common-scroll">
              {timeLineData?.map((actData: DailyData, ind: number) => (
                <tr key={ind}>
                  <td>{actData?.date}</td>
                  <td>
                    {actData?.total_worked_time
                      ? actData?.total_worked_time
                      : "NA"}
                  </td>
                  <td>{actData?.idle_time ? actData?.idle_time : "NA"}</td>
                  <td>
                    {actData?.start_time_in_am_pm
                      ? actData?.start_time_in_am_pm
                      : "NA"}
                  </td>
                  <td>
                    {actData?.end_time_in_am_pm
                      ? actData?.end_time_in_am_pm
                      : "NA"}
                  </td>

                  {actData?.timeLine?.map((cell, i) => (
                    <td key={i} className="box-container">
                      {cell?.length > 0 &&
                        cell?.map((segment, j) => (
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={
                              <Popover
                                id={`popover-basic-${segment?.start_time_am_pm}-${segment?.end_time_am_pm}`}
                              >
                                <Popover.Header as="h3">
                                  {actData?.date}
                                </Popover.Header>
                                <Popover.Body>
                                  <ul className="d-flex flex-column">
                                    <li>Application : {segment?.app}</li>
                                    <li>Category : {segment?.category}</li>
                                    <li>
                                      Activity Start :{" "}
                                      {segment?.start_time_am_pm}
                                    </li>
                                    <li>
                                      Activity End : {segment?.end_time_am_pm}
                                    </li>
                                    <li>Duration : {segment?.duration}</li>
                                  </ul>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <div
                              key={j}
                              className="time-line-box"
                              style={{
                                left: `${segment.start * 100}%`,
                                width: `${(segment.end - segment.start) * 100
                                  }%`,
                              }}
                            ></div>
                          </OverlayTrigger>
                        ))}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TimeLineTable;
