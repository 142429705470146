import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import Select from "../CustomComponents/select/Select";
import { commonGetService, commonPutService } from "../../utils/properties";
import { useAppDispatch } from "../../app/hooks";
import { showToast } from "../../features/Toaster/toastslice";
import { setProfilePic } from "../../features/settingSlice/settingSlice";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../app/hooks";

interface Profile {
    name: string;
    email: string;
    image: string;
    role: string;
    img_file?: File | null;
}

interface generalProps {
    refresh: boolean;
    adminData: (param: string) => void;
}

interface daysProps {
    value: boolean;
    title: string;
    date?: string;
}

const General = ({ refresh, adminData }: generalProps) => {
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const isPicUpdate = useAppSelector((state) => state.setting);

    const timeIntervals: { value: string; title: string }[] = [];

    const daysList: daysProps[] = [
        { value: false, title: "SUN" },
        { value: true, title: "MON" },
        { value: true, title: "TUE" },
        { value: true, title: "WED" },
        { value: true, title: "THU" },
        { value: true, title: "FRI" },
        { value: false, title: "SAT" },
    ];

    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const period = hour < 12 ? "AM" : "PM";
            const formattedHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 0 or 24 to 12 for AM/PM
            const formattedMinute = minute.toString().padStart(2, "0"); // Ensure double-digit minutes
            const formatted24Hour = hour.toString().padStart(2, "0"); // Ensure double-digit hours for 24-hour format
            const time12Hour = `${formattedHour}:${formattedMinute} ${period}`;
            const time24Hour = `${formatted24Hour}:${formattedMinute}`;
            timeIntervals.push({ value: time24Hour, title: time12Hour });
        }
    }

    const getFilteredimeIntervals = (
        selectedTime: string,
        type: "start" | "end"
    ) => {
        if (!selectedTime) return timeIntervals;

        return timeIntervals.filter(({ value }) => {
            if (type === "start") {
                // Start time should be before the selected end time
                return value < selectedTime;
            } else {
                // End time should be after the selected start time
                return value > selectedTime;
            }
        });
    };

    // useEffect(() =>{console.log("checking general rerender",isPicUpdate)},[isPicUpdate])

    const [profile, setProfile] = useState<Profile>({
        name: "",
        image: "",
        role: "",
        email: "",
        img_file: null,
    });
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPassword1, setShowPassword1] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [nameError, setNameError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
    const [startTimeError, setStartTimeError] = useState<string>("");
    const [endTimeError, setEndTimeError] = useState<string>("");
    const [spinner, setSpinner] = useState(true);
    const [workingDays, setWorkingDays] = useState<daysProps[]>(daysList);
    

    const handleModalClose = () => {
        setModalShow(false);
        setConfirmPasswordError("");
        setPasswordError("");
        setConfirmPassword("");
        setNewPassword("");
    };
    const handlePasswordShow = () => {
        setShowPassword(!showPassword);
    };
    const handlePasswordShow1 = () => {
        setShowPassword1(!showPassword1);
    };

    const handlePasswordRegexValidation = (value: string, key: string) => {
        if (key === "newPassword") {
            setNewPassword(value);
            setPasswordError("");
        } else if (key === "confirmPassword") {
            setConfirmPassword(value);
            setConfirmPasswordError("");
        }
    };

    const PasswordSubmit = () => {
        if (
            newPassword === confirmPassword &&
            newPassword === "" &&
            confirmPassword === ""
        ) {
            setPasswordError("password can't be empty");
            setConfirmPasswordError("password can't be empty");
            dispatch(
                showToast({
                    message: "password can't be empty",
                    status: "retry",
                    visible: true,
                })
            );
            return;
        }
        if (newPassword === confirmPassword) {
            setConfirmPasswordError("");
        } else {
            setConfirmPasswordError("Password must be same");
            dispatch(
                showToast({
                    message: "Password must be same",
                    status: "retry",
                    visible: true,
                })
            );
            return;
        }

        const passwordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const passed = passwordRegex.test(newPassword);
        if (passed) {
            setPasswordError("");
        } else {
            setPasswordError(
                "Password must contain at least 1 uppercase , 1 lowercase , 1 number, and 1 special character, and must be at least 8 characters long."
            );

            dispatch(
                showToast({
                    message:
                        "Password must contain at least 1 uppercase , 1 lowercase , 1 number, and 1 special character, and must be at least 8 characters long.",
                    status: "retry",
                    visible: true,
                })
            );
            return;
        }
        const passed1 = passwordRegex.test(confirmPassword);
        if (passed1) {
            setConfirmPasswordError("");
        } else {
            setConfirmPasswordError(
                "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and must be at least 8 characters long."
            );
            dispatch(
                showToast({
                    message: `${confirmPassword !== ""
                        ? "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and must be at least 8 characters long."
                        : "Password can't be empty"
                        }`,
                    status: "retry",
                    visible: true,
                })
            );
            return;
        }

        commonPutService(
            `/api/1.0/setting/update/password?new_password=${newPassword}`,
            {}
        ).then((res) => {
            if (res.status === 200) {
                dispatch(
                    showToast({
                        message: "Password updated successfully",
                        status: "success",
                        visible: true,
                    })
                );
                handleModalClose();
            } else {
                dispatch(
                    showToast({
                        message: "Something went wrong",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        });
    };

    const handleProfileUpdate = () => {
        if (!profile.name) {
            setNameError("Name is required");
        }
        if (!startTime) {
            setStartTimeError("Start time is required");
        }

        if (!endTime) {
            setEndTimeError("End time is required");
        }

        if (!profile.name || !startTime || !endTime) {
            dispatch(
                showToast({
                    message: "Fill the required fields",
                    status: "retry",
                    visible: true,
                })
            );
            return;
        }

        let data = new FormData();
        if (profile.img_file) {
            data.append("profile_picture", profile.img_file);
        }
        data.append("name", profile.name);
        data.append("startTime", startTime);
        data.append("endTime", endTime);
        commonPutService(`/api/1.0/setting/profile`, data).then((res) => {
            if (res.status === 200) {
                dispatch(
                    showToast({
                        message: "Profile updated successfully",
                        status: "success",
                        visible: true,
                    })
                );

                dispatch(
                    setProfilePic({
                        isPicUpdate: !isPicUpdate?.isPicUpdate,
                    })
                );
            }
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.size > 2 * 1024 * 1024) {
                dispatch(
                    showToast({
                        message: "File size must be less than 2MB",
                        status: "retry",
                        visible: true,
                    })
                );
                setProfile({ ...profile, img_file: null });
            } else {
                let url = URL.createObjectURL(file);
                setProfile({ ...profile, img_file: file, image: url });
            }
        }
    };

    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Programmatically trigger the file input click
        }
    };

    const getProfileData = () => {
        commonGetService(`/api/1.0/setting/profile/id`).then((res) => {
            if (res.status === 200) {
                setProfile({
                    name: res.data.name,
                    image: res.data.profile_picture_path,
                    role: res.data.role_name,
                    email: res.data.email,
                });
                setStartTime(res.data.start_time);
                setEndTime(res.data.end_time);

                adminData(res.data.role_name);
            }
            setSpinner(false);
        });
    };
    const getWorkingsDays = () => {
        commonGetService(`/api/1.0/setting/work/day`).then((res) => {
            if (res.status === 200) {
                setWorkingDays(res.data);
            } else {
                setWorkingDays([]);
            }
        });
    };

    useEffect(() => {
        getProfileData();
        getWorkingsDays();
    }, [refresh]);

    return (
        <>
            {spinner ? (
                <div className="d-flex justify-content-center align-items-center h-100 crt-loader h-20">
                    <img src="/images/loader/content_loader.gif" />
                    {/* <Spinner animation="border" /> */}
                </div>
            ) : (
                <div className="general">
                    <h5>Profile Settings</h5>
                    <Row className="m-0 filter-container">
                        <Col className="p-0 " lg={2}>
                            <div className="d-block pic-container">
                                <div
                                    className={
                                        profile?.image
                                            ? "employee-live-pic-container"
                                            : "employee-live-pic-container fill-color-avatar"
                                    }
                                >
                                    {profile?.image ? (
                                        <img src={profile?.image} alt={profile?.name} />
                                    ) : (
                                        <span>{profile?.name.charAt(0).toUpperCase()}</span>
                                    )}
                                </div>
                                <button className="edit-button" onClick={handleUploadClick}>
                                    Edit
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </Col>
                        <Col className="p-0 " lg={4}>
                            <Form.Group className="">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    name="Name"
                                    type="text"
                                    className={nameError ? "error-msg" : ""}
                                    value={profile.name}
                                    placeholder="Enter Name "
                                    onChange={(e) => {
                                        setProfile({ ...profile, name: e.target.value });
                                        setNameError("");
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className=" pass-grp">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    name="Role"
                                    type="text"
                                    value={profile.role}
                                    placeholder="Enter Role"
                                    // onChange={(e) => { }}
                                    autoComplete="off"
                                    disabled
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-0 " lg={4}>
                            <Form.Group className="">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    name="userEmail"
                                    type="email"
                                    value={profile.email}
                                    placeholder="Enter Email Address"
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group className=" pass-grp position-relative">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    name="userPassword"
                                    type="text"
                                    value={"***********"}
                                    placeholder="Generate Password"
                                    className="pass-input"
                                    autoComplete="off"
                                    readOnly
                                />
                                <button
                                    className="change-password position-absolute"
                                    onClick={() => setModalShow(true)}
                                >
                                    Change password
                                </button>
                            </Form.Group>
                        </Col>
                    </Row>
                    {workingDays.length > 0 && (
                        <>
                            {" "}
                            <h5>Working Days</h5>
                            <Row className="select-days">
                                <Col className="p-0 " lg={6}>
                                    <Form.Group className="timeInterval">
                                        {/* <Form.Label>Select Days</Form.Label> */}
                                        <div className="d-flex align-items-center  flex-wrap days-wrapper">
                                            {workingDays.map((item, index) => {
                                                return (
                                                    <button
                                                        key={index}
                                                        className={`${item.value ? "active" : ""
                                                            } days-button`}
                                                    >
                                                        {item.title}
                                                    </button>
                                                );
                                            })}
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row className="drop-row">
                        <Col className="p-0 " lg={3}>
                            <Form.Group className="timeInterval">
                                <Form.Label>Work Start Time</Form.Label>
                                <Select
                                    value={startTime}
                                    options={getFilteredimeIntervals(endTime, "start")}
                                    onChange={(val) => {
                                        setStartTime(val);
                                    }}
                                    placeholder="Select one"
                                    width="100%"
                                    margin="0.75rem 0 0"
                                    className={`${startTimeError ? "error-msg" : ""}`}
                                    disabled={profile.role.toLowerCase() === "admin" ? false : true}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-0 " lg={3}>
                            <Form.Group className="timeInterval">
                                <Form.Label>Work End Time</Form.Label>
                                <Select
                                    value={endTime}
                                    options={getFilteredimeIntervals(startTime, "end")}
                                    onChange={(val) => {
                                        setEndTime(val);
                                    }}
                                    placeholder="Select one"
                                    width="100%"
                                    margin="0.75rem 0 0"
                                    className={`${endTimeError ? "error-msg" : ""}`}
                                    disabled={profile.role.toLowerCase() === "admin" ? false : true}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button
                        className="add-btn"
                        disabled={false}
                        onClick={() => {
                            handleProfileUpdate();
                        }}
                    >
                        Save
                    </Button>
                    <Modal
                        show={modalShow}
                        onHide={() => handleModalClose()}
                        centered
                        size="lg"
                        className="change-password-pop"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Change Your Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className=" position-relative">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        name="userEmail"
                                        type={showPassword ? "text" : "password"}
                                        value={newPassword}
                                        placeholder="Enter New Password"
                                        onChange={(e) =>
                                            handlePasswordRegexValidation(
                                                e.target.value,
                                                "newPassword"
                                            )
                                        }
                                        autoComplete="off"
                                        className={passwordError ? "error-msg" : ""}
                                    />
                                    {!showPassword ? (
                                        <Image
                                            src="/images/login/password_hide.svg"
                                            alt="Password Hide"
                                            className="position-absolute cursor-pointer"
                                            onClick={handlePasswordShow}
                                        />
                                    ) : (
                                        <Image
                                            src="/images/login/password_show.svg"
                                            alt="Password Show"
                                            className="position-absolute cursor-pointer"
                                            onClick={handlePasswordShow}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group className=" pass-grp position-relative">
                                    <Form.Label>Confirm Password</Form.Label>

                                    <Form.Control
                                        name="userPassword"
                                        type={showPassword1 ? "text" : "password"}
                                        value={confirmPassword}
                                        placeholder="Re-enter New Password"
                                        // className='pass-input'
                                        onChange={(e) => {
                                            handlePasswordRegexValidation(
                                                e.target.value,
                                                "confirmPassword"
                                            );
                                        }}
                                        autoComplete="off"
                                        className={confirmPasswordError ? "error-msg" : ""}
                                    />

                                    {!showPassword1 ? (
                                        <Image
                                            src="/images/login/password_hide.svg"
                                            alt="Password Hide"
                                            className="position-absolute cursor-pointer"
                                            onClick={handlePasswordShow1}
                                        />
                                    ) : (
                                        <Image
                                            src="/images/login/password_show.svg"
                                            alt="Password Show"
                                            className="position-absolute cursor-pointer"
                                            onClick={handlePasswordShow1}
                                        />
                                    )}
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className="cnl-btn"
                                variant="outline-secondary"
                                disabled={false}
                                onClick={() => handleModalClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="add-btn"
                                disabled={false}
                                onClick={() => {
                                    PasswordSubmit();
                                }}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default General;
