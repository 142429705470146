  import React, { useEffect, useRef, useState } from "react";
  import {
    Bar,
    CartesianGrid,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    ResponsiveContainer,
    Text,
  } from "recharts";
  import "../../styles/barchart.scss";
  import { InsigtsData } from "../Dashboard/ProductiveDetails";
  import { useAppSelector } from "../../app/hooks";
  import { Spinner } from "react-bootstrap";

  interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    coordinate?: { x: number; y: number };
    xAxisTicks: { coordinate: number }[];
    isAnimationActive: boolean;
  }

  const Barcharts: React.FC<{
    insightsData: InsigtsData[];
    chartLoading: boolean;
  }> = ({ insightsData, chartLoading }) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const parentref = useRef<HTMLDivElement>(null);
    const tooltipref = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<InsigtsData[]>([]);
    const [nonEmptyData, setNonEmptyData] = useState<InsigtsData[]>([]);
    const [xAxisTicks, setXAxisTicks] = useState<{ coordinate: number }[]>([]);
    const [rightMost, setRightMost] = useState<boolean>(false);
    const filterData = useAppSelector((state) => state.filterData);
    const [tooltipPosition, setTooltipPosition] = React.useState({ x: 0, y: 0 });
    const { startDate, endDate, empDeviceId, filterType } = filterData; // Destructuring the filterData state value
    
    // const data = [
    //   {
    //     Department: "Page A",
    //     Productive: 4000,
    //     Unproductive: 2400,
    //     Idle: 1000,
    //     Neutral: 500,
    //     total_hours: 7900,
    //   },
    //   {
    //     Department: "A Very Long Department Name that Needs Ellipsis",
    //     Productive: 3000,
    //     Unproductive: 1398,
    //     Idle: 1200,
    //     Neutral: 700,
    //     total_hours: 7298,
    //   },
    //   {
    //     Department: "Page C",
    //     Productive: 2000,
    //     Unproductive: 9800,
    //     Idle: 800,
    //     Neutral: 300,
    //     total_hours: 12900,
    //   },
    //   {
    //     Department: "Page D",
    //     Productive: 2780,
    //     Unproductive: 3908,
    //     Idle: 600,
    //     Neutral: 200,
    //     total_hours: 7488,
    //   },
    //   {
    //     Department: "Page E",
    //     Productive: 1890,
    //     Unproductive: 4800,
    //     Idle: 900,
    //     Neutral: 400,
    //     total_hours: 7990,
    //   },
    //   {
    //     Department: "Page F",
    //     Productive: 2390,
    //     Unproductive: 3800,
    //     Idle: 700,
    //     Neutral: 300,
    //     total_hours: 7190,
    //   },
    //   {
    //     Department: "Page G",
    //     Productive: 3490,
    //     Unproductive: 4300,
    //     Idle: 800,
    //     Neutral: 200,
    //     total_hours: 8790,
    //   },
    //   {
    //     Department: "Page H",
    //     Productive: 2390,
    //     Unproductive: 3800,
    //     Idle: 600,
    //     Neutral: 400,
    //     total_hours: 8190,
    //   },
    //   {
    //     Department: "Page I",
    //     Productive: 3490,
    //     Unproductive: 4300,
    //     Idle: 500,
    //     Neutral: 300,
    //     total_hours: 8590,
    //   },
    //   {
    //     Department: "Page J",
    //     Productive: 2390,
    //     Unproductive: 3800,
    //     Idle: 700,
    //     Neutral: 200,
    //     total_hours: 8090,
    //   },
    //   {
    //     Department: "Page K",
    //     Productive: 3490,
    //     Unproductive: 4300,
    //     Idle: 600,
    //     Neutral: 300,
    //     total_hours: 8690,
    //   },
    //   {
    //     Department: "Page L",
    //     Productive: 2390,
    //     Unproductive: 3800,
    //     Idle: 500,
    //     Neutral: 400,
    //     total_hours: 8090,
    //   },
    //   {
    //     Department: "Page M",
    //     Productive: 3490,
    //     Unproductive: 4300,
    //     Idle: 800,
    //     Neutral: 200,
    //     total_hours: 8790,
    //   },
    //   {
    //     Department: "Page N",
    //     Productive: 2390,
    //     Unproductive: 3800,
    //     Idle: 600,
    //     Neutral: 300,
    //     total_hours: 8090,
    //   },
    //   {
    //     Department: "Page O",
    //     Productive: 3490,
    //     Unproductive: 4300,
    //     Idle: 500,
    //     Neutral: 400,
    //     total_hours: 8690,
    //   },
    // ];

    const LegendCategories = [
      {
        category: "Productive",
        color: "#83DF9F",
      },
      {
        category: "Unproductive",
        color: " #B7DDFF",
      },

      {
        category: "Neutral",
        color: "#A1ACFF",
      },
      {
        category: "Idle",
        color: "#DB8863",
      },
    ];

    const Legend2: React.FC = () => (
      <>
        <div className="flex-center">
          <h4 className="heading-mb">
            {filterType === "employee"
              ? "Productivity insights"
              : "Department wise productivity insights"}
          </h4>
        </div>
      </>
    );
    const numberOfDataPoints = data.length;
    const containerWidth = Math.max(800, numberOfDataPoints * 140); // Set a minimum width and adjust based on the number of data points
    //   const tickInterval = containerWidth / numberOfDataPoints;
    const roundedRectBottom = (
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number
    ) => {
      const radiusBottom = Math.min(radius, height / 2); // Ensure radius doesn't exceed half the height
      return `M${x},${y}
              L${x + width},${y}
              L${x + width},${y + height - radiusBottom}
              A${radiusBottom},${radiusBottom} 0 0 1 ${x + width - radiusBottom
        },${y + height}
              L${x + radiusBottom},${y + height}
              A${radiusBottom},${radiusBottom} 0 0 1 ${x},${y + height - radiusBottom
        }
              Z`;
    };
    const CustomBarBottom: React.FC<{
      x?: number;
      y?: number;
      width?: number;
      height?: number;
      fill?: string;
    }> = (props) => {
      const { x, y, width, height, fill } = props;
      if (
        x === undefined ||
        y === undefined ||
        width === undefined ||
        height === undefined ||
        fill === undefined
      ) {
        return null; // Return null if any of the required props are missing
      }
      return <path d={roundedRectBottom(x, y, width, height, 5)} fill={fill} />;
    };

    const roundedRectTop = (
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number
    ) => {
      // Calculate the adjusted radius for the top corners
      const adjustedRadius = Math.min(radius, height / 2);

      // Construct the SVG path string with rounded top corners
      return `M${x},${y + adjustedRadius}
        A${adjustedRadius},${adjustedRadius} 0 0,1 ${x + adjustedRadius},${y}
        L${x + width - adjustedRadius},${y}
        A${adjustedRadius},${adjustedRadius} 0 0,1 ${x + width},${y + adjustedRadius
        }
        L${x + width},${y + height}
        L${x},${y + height}
        Z`;
    };
    const CustomBarTop: React.FC<{
      x?: number;
      y?: number;
      width?: number;
      height?: number;
      fill?: string;
    }> = (props) => {
      const { x, y, width, height, fill } = props;
      if (
        x === undefined ||
        y === undefined ||
        width === undefined ||
        height === undefined ||
        fill === undefined
      ) {
        return null; // Return null if any of the required props are missing
      }
      return <path d={roundedRectTop(x, y, width, height, 5)} fill={fill} />;
    };

    const customTickFormatter = (tickValue: string) => {
      const maxLength = 15;
      if (tickValue.length > maxLength) {
        return `${tickValue.substring(0, maxLength)}...`;
      }
      return tickValue;
    };

    const CustomLegend: React.FC = () => (
      <div className=" flex-center legend-center-bar">
        {LegendCategories.map((item, index) => (
          <React.Fragment key={`${index}legend`}>
            <span
              className="circle-legend"
              style={{
                backgroundColor: item.color,
              }}
            ></span>
            <span className="m-l-10">{item.category}</span>
          </React.Fragment>
        ))}
      </div>
    );
    useEffect(() => {
      const handleMouseMove = (event: MouseEvent) => {
        setTooltipPosition({
          x: event.clientX,
          y: event.clientY, 
        });
      };
    
      window.addEventListener("mousemove", handleMouseMove);
      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }, []);

    const CustomTooltip: React.FC<CustomTooltipProps> = ({
      active,
      payload,
      coordinate,
      xAxisTicks,
    }) => {
      const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
      const tooltipref = useRef<HTMLDivElement | null>(null); // Add ref for tooltip element
      const chartRef = useRef<HTMLDivElement | null>(null); // Add ref for chart element
      const [isVisible, setIsVisible]= useState<boolean>(true);

      const handleMouseMove = (event: MouseEvent) => {
        setTooltipPosition({
          x: event.clientX,
          y: event.clientY,
        });
      };

      useEffect(() => {
        const handleWheel = (event:any) => {
          const isScrollingHorizontally = event.deltaX !== 0; 
          const isScrollingVertically = event.deltaY !== 0;   
          if (isScrollingHorizontally || isScrollingVertically) {
            setIsVisible(false);
          }
        };  
        window.addEventListener('wheel', handleWheel);  
        return () => {
          window.removeEventListener('wheel', handleWheel);
        };
      }, []);

      useEffect(() => {
        const handleWheel = (event:any) => {
          const isScrollingHorizontally = event.deltaX !== 0; 
          const isScrollingVertically = event.deltaY !== 0;   
          if (isScrollingHorizontally || isScrollingVertically) {
            setIsVisible(false);
          }
        };  
        window.addEventListener('scroll', handleWheel);  
        return () => {
          window.removeEventListener('scroll', handleWheel);
        };
      }, []);

      useEffect(() => {
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
          window.removeEventListener("mousemove", handleMouseMove);
        };
      }, []);
          
      useEffect(() => {
        if (coordinate) {
          setTooltipPosition({
            x: coordinate.x,
            y: coordinate.y,
          });
        }
      }, [coordinate]);
    
      if (
        active &&
        payload &&
        payload.length &&
        payload[0]?.payload?.total_hours &&
        payload[0]?.payload?.total_hours !== 0
      ) {
        // if (nonEmptyData.length > 1 && xAxisTicks.length > 0) {
          const { x, y } = coordinate || { x: 0, y: 0 };   

          if (chartRef.current && tooltipref?.current) {
            const chartWidth = chartRef.current.offsetWidth;
            const tooltipwidth = tooltipref.current.offsetWidth;

            if (x + tooltipwidth > chartWidth) {
              setRightMost(true);
              
            } else {
              setRightMost(false);
            }
          } else {
            setRightMost(false);

          }
        
        return isVisible ? (
          <>
            <div
          className="tooltip-barchart"ref={tooltipref}>
              <h6>
                {filterType === "organization" || filterType === "department"
                  ? payload[0]?.payload?.Department
                  : payload[0]?.payload?.timestamp}
              </h6>
              <div className="flex-start total">
                <span className="category-width ">Total Hours</span>
                &nbsp;-&nbsp;
                <span className="ml-auto">
                  {payload[0]?.payload["total_hours"]} &nbsp;Hr
                </span>
              </div>
              <hr />
              {LegendCategories.map((item, index) => {
                return (
                  <div className="flex-start" key={`${index}tools`}>
                    <span
                      className="circle-legend m-t-10"
                      style={{
                        backgroundColor: item.color,
                      }}
                    ></span>
                    <span className="category-width ">{item.category}</span>
                    &nbsp;-&nbsp;
                    <span className="ml-auto">
                      {payload[0]?.payload[item?.category]} &nbsp; Hr
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        ) : null;
      }
      return null;
    };
    
    useEffect(() => {
      if (chartRef.current && insightsData.length > 1) {
        const chartWidth = chartRef.current.offsetWidth;
        const numDataPoints = insightsData.length;
        const tickSpacing = chartWidth / (numDataPoints - 1);

        const newTicks = insightsData.map((entry, index) => ({
          coordinate: index * tickSpacing,
        }));

        setXAxisTicks(newTicks);
      }

      let newData = [...insightsData];

      let a = [...insightsData].reverse();

      const filteredData = a.filter((item) => {
        return item.total_hours !== 0;
      });

      setNonEmptyData([...a]);

      setData([...a]);
    }, [insightsData]);

    return (
      <div className="bar-chart-container p-20" ref={parentref}>
        <Legend2 />
        {chartLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100 crt-loader">
              <img src="/images/loader/content_loader.gif"/>
              {/* <Spinner animation="border" /> */}
            </div>
          ) :(
        <ResponsiveContainer
          // width={containerWidth}
          width={
            filterType === "organization" || filterType === "department"
              ? data.length > 5
                ? containerWidth
                : "100%"
              : containerWidth
          }
          // height={374}
          className="m-t-20"
          ref={chartRef}
        >
               <BarChart
              data={data}
              margin={{
                top: 55,
                right: 0,
                left: 20,
                bottom: 15,
              }}
            >
              <CartesianGrid horizontal={false} vertical={false} />
              <XAxis
                dataKey={
                  filterType === "organization" || filterType === "department"
                    ? "Department"
                    : "day"
                }
                axisLine={false}
                tickLine={false}
                tickFormatter={customTickFormatter}
              />

              <YAxis
                axisLine={false}
                tick={true}
                tickLine={false}
                padding={{ top: 0, bottom: 0 }}
                tickCount={5}
                allowDecimals={false}
                tickMargin={15}
              />
              <Tooltip
                cursor={false}
                content={<CustomTooltip xAxisTicks={xAxisTicks} isAnimationActive={false} />}
                wrapperStyle={{
                  position: "fixed",
                  left: rightMost ? `${tooltipPosition.x - 100}px` : `${tooltipPosition.x -125}px`,
                  top: `${tooltipPosition.y-205}px`,
                }}
              />
              <Legend content={<CustomLegend />} verticalAlign="bottom" />
              <Bar
                dataKey="Unproductive"
                fill="#F6AEAB"
                stackId={"A"}
                barSize={30}
                shape={<CustomBarBottom />}
              />
              <Bar
                dataKey="Productive"
                fill="#83DF9F"
                stackId={"A"}
                barSize={30}
              />
              <Bar dataKey="Neutral" fill="#B7DDFF" stackId={"A"} barSize={30} />
              <Bar
                dataKey="Idle"
                fill="#A1ACFF"
                stackId={"A"}
                barSize={30}
                shape={<CustomBarTop />}
              />
            </BarChart>
          
        </ResponsiveContainer>
          )}
      </div>
    );
  };

  export default Barcharts;
