import React, { useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import './datePicker.scss';

interface PropsObject {
    selectedDate: Date | null,
    handleDateChange: (val: Date | null, name: string) => void,
    calTitle: string,
    isError: boolean
}

const Calendar = (props: PropsObject) => {
    const { selectedDate, handleDateChange, calTitle, isError } = props

    const [isMonthPic, setIsMonthPic] = useState<boolean>(false);
    const [isYearPic, setIsYearPic] = useState<boolean>(false);
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const weekDays = [
        "SUN",
        "MON",
        "TUE",
        "WED",
        "THU",
        "FRI",
        "SAT"
    ]

    const handleOnChange = (date: Date | null) => {
        if (selectedDate) {
            if (isMonthPic) {
                // date?.setDate(selectedDate?.getDate());
                handleDateChange(date as Date, calTitle);
                setIsMonthPic(false);
            }
            else if (isYearPic) {
                // date?.setFullYear(date?.getFullYear(), selectedDate?.getMonth(), selectedDate?.getDate());
                handleDateChange(date as Date, calTitle);
                setIsYearPic(false)
                setIsMonthPic(true);
            }
            else {
                handleDateChange(date as Date, calTitle);
            }
        }
        else {
            if (isMonthPic) {
                setIsMonthPic(false);
                handleDateChange(date as Date, calTitle);
            }
            else if (isYearPic) {
                setIsYearPic(false);
                setIsMonthPic(true);
            }
            else if (!isMonthPic && !isYearPic) {
                handleDateChange(date as Date, calTitle);
            }
            // handleDateChange(date as Date, calTitle);
        }
    }

    return (
        <div className={`cal-container ${(isMonthPic || isYearPic) ? 'sml-cal' : ''}`}>
            <DatePicker
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => {
                    const currentYear = date.getFullYear();

                    return (
                        <div className='d-flex flex-column cal-header'>
                            <div className="d-flex flex-row justify-content-between align-items-center month-year">
                                <Image
                                    src='/images/reports/left_arrow.svg'
                                    onClick={() => {
                                        if (isYearPic) {
                                            // Decrease the visible year range by 10 years
                                            changeYear(currentYear - 10);
                                        }
                                        else if (isMonthPic) {
                                            changeYear(currentYear - 1); // Decrease by 1 year
                                        }
                                        else {
                                            decreaseMonth();
                                        }
                                    }}
                                />
                                <p className='mb-0 mt-1'>
                                    <span
                                        onClick={() => {
                                            setIsMonthPic(true);
                                            setIsYearPic(false);
                                        }}
                                    >
                                        {months[date.getMonth()]}
                                    </span>&nbsp;&nbsp;&nbsp;
                                    <span
                                        onClick={() => {
                                            setIsYearPic(true);
                                            setIsMonthPic(false);
                                        }}
                                    >
                                        {currentYear}
                                    </span>
                                </p>
                                <Image
                                    src='/images/reports/right_arrow.svg'
                                    onClick={() => {
                                        if (isYearPic) {
                                            // Increase the visible year range by 10 years
                                            changeYear(currentYear + 10);
                                        } else if (isMonthPic) {
                                            changeYear(currentYear + 1); // Increase by 1 year
                                        } else {
                                            increaseMonth();
                                        }
                                    }}
                                />
                            </div>
                            {!isMonthPic && !isYearPic &&
                                <div className='d-flex justify-content-between mt-2 day-name'>
                                    {weekDays?.map((week, ind) => (
                                        <div key={ind}>{week}</div>
                                    ))}
                                </div>
                            }
                        </div>
                    );
                }}
                selected={selectedDate}
                closeOnScroll={true}
                dateFormat="dd-MMM-YYYY"
                placeholderText={calTitle === "fromDate" ? "From Date" : "To Date"}
                showMonthYearPicker={isMonthPic}
                showYearPicker={isYearPic}
                onKeyDown={(e) => e.preventDefault()} // Keypress restriction
                onClickOutside={() => { setIsMonthPic(false); setIsYearPic(false) }}
                calendarClassName={(isMonthPic && !isYearPic) ? 'month-cal' : isYearPic ? 'year-cal' : ''}
                shouldCloseOnSelect={(!isMonthPic && !isYearPic) ? true : false}
                onChange={(date) => { handleOnChange(date) }}
                onSelect={(date) => { (selectedDate as Date)?.toLocaleDateString() === date?.toLocaleDateString() && handleOnChange(date) }}
                // minDate={props?.minDate}
                maxDate={new Date()}
                popperPlacement="bottom-end"
            />
            <Image src='/images/reports/calendar.svg' alt='Calendar' className='cal-icon' onClick={(e) => 
            {
                e.stopPropagation();
                const dateInputs = document.querySelectorAll('.react-datepicker__input-container input'); 
                if (dateInputs.length >= 2) {
                const targetInput = calTitle === 'fromDate' ? dateInputs[0] : dateInputs[1]; 
                (targetInput as HTMLInputElement).focus();
                }
                }} 
            />
            {isError && <p className='err-txt'>Select the from date</p>}
        </div>
    );
};

export default Calendar;