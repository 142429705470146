import axios, { AxiosInstance } from "axios";
import moment from "moment";

type FilterApi = (
  startDate: Date,
  endDate: Date,
  filterType: string,
  organization: string,
  empDeviceId: string,
  department: string,
  isLimit: boolean
) => string;

// export const baseURL = 'http://192.168.1.118:5700'; //for dev
// export const baseURL = 'http://tracker.mytimesheet.in:5700'; //for prod
export const baseURL = process.env.REACT_APP_API_URL; //using env file

// // Create a new Axios instance with default configuration
// const axiosInstance: AxiosInstance = axios.create({
//     baseURL: 'http://tracker.mytimesheet.in:5700', // Set the base URL for all requests
//     headers: {
//         'Content-Type': 'application/json', // Set default Content-Type header
//         'token': `${getCookie()}`, // Set default token header
//     }
// });

export async function commonGetService(url: string, signal?: AbortSignal) {
  return axios
    .get(baseURL + url, {
      headers: { token: `${getCookie()}` },
      signal
    })
    .then((response) => {
      if (response.data.status === 401) {
        document.cookie =
          "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.clear();
        window.location.href = "/";
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        return { data: null, message: "Request cancelled", status: "cancelled" };
      }
      if (error.response && error.response.status === 401) {
        return {
          data: null,
          message: "Unauthorized",
          status: 401,
        };
      }

    });
}

export async function commonPostservice(url: string, data: unknown) {
  if (getCookie()) {
    return axios
      .post(baseURL + url, data, { headers: { token: `${getCookie()}` } })
      .then((response) => {
        if (response.data.status === 401) {
          document.cookie =
            "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.clear();
          window.location.href = "/";
        } else {
          return response.data;
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          return {
            data: null,
            message: "Unauthorized",
            status: 401,
          };
        }
      });
  } else {
    return axios
      .post(baseURL + url, data)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          return {
            data: null,
            message: "Unauthorized",
            status: 401,
          };
        }
      });
  }
}

export async function commonDeleteService(url: string) {
  return axios
    .delete(baseURL + url, { headers: { token: `${getCookie()}` } })
    .then((response) => {
      if (response.data.status === 401) {
        document.cookie =
          "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.clear();
        window.location.href = "/";
      } else {
        return response.data;
      }
    })
    .catch(function (error) {
      if (error.response && error.response.status === 401) {
        return {
          data: null,
          message: "Unauthorized",
          status: 401,
        };
      }
    });
}

export async function commonPutService(url: string, data?: unknown) {
  if (data !== undefined) {
    return axios
      .put(baseURL + url, data, { headers: { token: `${getCookie()}` } })
      .then((response) => {
        if (response.data.status === 401) {
          document.cookie =
            "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.clear();
          window.location.href = "/";
        } else {
          return response.data;
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 401) {
          return {
            data: null,
            message: "Unauthorized",
            status: 401,
          };
        }
      });
  } else {
    return axios
      .put(baseURL + url, { headers: { token: `${getCookie()}` } })
      .then((response) => {
        if (response.data.status === 401) {
          document.cookie =
            "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          localStorage.clear();
          window.location.href = "/";
        } else {
          return response.data;
        }
      })
      .catch(function (error)     {

        if (error.response && error.response.status === 401) {
          return {
            data: null,
            message: "Unauthorized",
            status: 401,
          };
        }
      });
  }
}

export const getCookie = () => {
  try {
    const cookieString = document.cookie || "";
    let lastToken;
    for (const cookie of cookieString.split(";")) {
      const cookieArray = cookie.split("=");
      if (cookieArray[0].trim() === "X-XSRF-TOKEN") {
        lastToken = cookieArray[1].trim();
        return lastToken;
      }
    }
  } catch {
    return;
  }
};

export const handleFilterApi: FilterApi = (
  startDate,
  endDate,
  filterType,
  organization,
  empDeviceId,
  department,
  isLimit
) => {
  let fromDate = moment(startDate).format("YYYY-MM-DD");
  let toDate = moment(endDate).format("YYYY-MM-DD");

  if (filterType === "organization" && organization === "All Organizations") {
    if (!isLimit) {
      return `startDate=${fromDate}&endDate=${toDate}`;
    } else {
      return `startDate=${fromDate}&endDate=${toDate}&limit=5`;
    }
  } else if (
    filterType === "organization" &&
    organization !== "All Organizations"
  ) {
    if (!isLimit) {
      return `startDate=${fromDate}&endDate=${toDate}&organization=${organization}`;
    } else {
      return `startDate=${fromDate}&endDate=${toDate}&limit=5&organization=${organization}`;
    }
  } else if (filterType === "employee") {
    if (!isLimit) {
      return `startDate=${fromDate}&endDate=${toDate}&deviceId=${empDeviceId}`;
    } else {
      return `startDate=${fromDate}&endDate=${toDate}&limit=5&deviceId=${empDeviceId}`;
    }
  } else {
    if (!isLimit) {
      return `startDate=${fromDate}&endDate=${toDate}&department=${department}`;
    } else {
      return `startDate=${fromDate}&endDate=${toDate}&limit=5&department=${department}`;
    }
  }
};
