import React, { useEffect, useRef, useState, useMemo } from "react";
import { Row, Col, Image, Modal, Carousel, Form } from "react-bootstrap";
import { commonGetService, handleFilterApi } from "../../utils/properties";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Skeleton from "react-loading-skeleton";
import { showToast } from "../../features/Toaster/toastslice";

interface ScreenShots {
  url: string;
  description: string;
  device_id: string;
  emp_name: string;
  emp_code: string;
}
const Screenshots = ({ height }: { height: number }) => {
  const controllerRef = useRef<AbortController | null>(null)
  const observerTarget = useRef(null);
  const screenshotCardRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const screenshotCardsWrapperRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [screenshots, setScreenshots] = useState<ScreenShots[]>([]);
  const filterData = useAppSelector((state) => state.filterData);
  const [page, setPage] = useState<number | string>(0)
  const [pageSize, setPageSize] = useState<number>(0)
  const [pageHeight, setPageHeight] = useState<Number>(0)
  const dispatch = useAppDispatch();
  const showScreenshot = process.env.REACT_APP_DISPLAY_SCREENSHOT;
  const {
    startDate,
    endDate,
    empDeviceId,
    filterType,
    organization,
    department,
  } = filterData; // Destructuring the filterData state value

  useEffect(() => {
    setIsLoading(true);
    setPage(0);
    setScreenshots([]);
    if (Number(pageSize) > 0) {

      controllerRef.current = new AbortController();
      const { signal } = controllerRef.current;

      commonGetService(
        `/api/1.0/dashboard/screenshots/recent?${handleFilterApi(
          startDate,
          endDate,
          filterType,
          organization,
          empDeviceId,
          department,
          false
        )}&page=${0}&size=${pageSize}`,
        signal
      ).then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          if (!Number(page)) {
            setScreenshots(res?.data);
          } else {
            setScreenshots((prevScreenshots) => [...prevScreenshots, ...res?.data]);
          }
        } else {
          setIsLoading(true);
          // dispatch(
          //   showToast({
          //     message: "Something went wrong in API.",
          //     status: "retry",
          //     visible: true,
          //   })
          // );
        }
      }).catch((err) => {
        if (err.name !== "AbortError") {
          setIsLoading(false);
        }
      });
    }
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [startDate,
    endDate,
    empDeviceId,
    filterType,
    organization,
    department]);

  useEffect(() => {
    if (pageSize > 0) {
      getScreenShots(0); // Call only when pageSize is set
    }
  }, [pageSize]);

  useEffect(() => {
    if (Number(page) > 0) {
      getScreenShots();
    }
  }, [page]);

  useEffect(() => {


    const observer = new IntersectionObserver(entries => {
      const isIntersecting = entries[0].isIntersecting;


      // Only trigger if going from "not intersecting" -> "intersecting"
      if (isIntersecting && !isLoading) {
        setPage((prevPage) => Number(prevPage) + 1);
      }

      // Update last state
    }, { root: null, threshold: 0.1, rootMargin: "100px" });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [isLoading]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const updatePageSize = () => {
      if (page !== 0) return null

      const containerHeight = height;
      const containerWidth =
        containerRef.current?.getBoundingClientRect().width || window.innerWidth;
      const cardHeight =
        screenshotCardRef.current?.getBoundingClientRect().height || 200;
      const cardWidth =
        screenshotCardRef.current?.getBoundingClientRect().width || 200;
      // const gap = 26; // Adjust based on your CSS
      const computedStyle = window.getComputedStyle(screenshotCardsWrapperRef.current!);
      let gapValue = computedStyle.gap || computedStyle.rowGap || "20px"; // Fallback to 20px
      let gap1 = parseFloat(gapValue); // Convert to number

      let gap = gapValue.includes("%")
        ? (gap1 / 100) * screenshotCardsWrapperRef.current!.offsetWidth
        : gap1;


      if (containerHeight && containerWidth && cardHeight && cardWidth) {
        const cardsPerRow = Math.max(1, Math.floor((containerWidth + gap) / (cardWidth + gap)));

        const visibleRows = Math.floor((containerHeight + gap) / (cardHeight + gap));
        const totalVisibleCards = (cardsPerRow * visibleRows || 10) + 1;

        setPageSize(totalVisibleCards);
      }

    };


    if (page === 0) { updatePageSize(); setPageHeight(height) }

    // 
    return () => {

    };
  }, [height]);



  const getScreenShots = (number = page) => {
    if (pageSize === 0) return


    if (page === 0) setIsLoading(true);

    commonGetService(
      `/api/1.0/dashboard/screenshots/recent?${handleFilterApi(
        startDate,
        endDate,
        filterType,
        organization,
        empDeviceId,
        department,
        false
      )}&page=${number}&size=${pageSize}`
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        if (!Number(page)) {
          setScreenshots(res?.data);
        } else {
          setScreenshots((prevScreenshots) => [...prevScreenshots, ...res?.data]);
        }
      } else {
        setIsLoading(true);
        // dispatch(
        //   showToast({
        //     message: "Something went wrong in API.",
        //     status: "retry",
        //     visible: true,
        //   })
        // );
      }
    });
  }


  const handleModal = (data: string) => {
    setShowModal(!showModal);

    let index = screenshots?.findIndex((item) => item?.url === data);
    setActiveIndex(index);
  };
  const arr = Array(8).fill(0);
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.minHeight = pageHeight + "px"; // Reset height on filter change
    }
  }, [filterData]);

  return (
    <div className="sst-container d-flex flex-column screenshots-page-container new-page" ref={containerRef}  >
      <div className="d-flex flex-row justify-content-between align-items-center page-heading">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h4>ScreenShots</h4>
          <span>{screenshots.length} Nos</span>
        </div>
        {/* {filterType !== "employee" ?
          (isLoading ?
            <Skeleton className='cus-progress w-100 search-screenshot-loader' containerClassName="skel-progress" count={1} style={{ width: "16.795rem" }} />
            :
            <>
              <div className="search-bar position-relative screenshots-search d-flex align-items-center justify-content-start">

                <Image
                  src="/images/dashboard/grey_search.svg"
                  alt="Search_Icon"
                // className="position-absolute"
                />
                <Form.Control
                  type="text"
                  placeholder="Search emp name, Emp ID"
                // value={searchText}
                // onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </>)
          :
          <></>
        } */}
      </div>

      <div className="sct-img-cont d-flex flex-row cursor-pointer screenshot-cards-wrapper" ref={screenshotCardsWrapperRef}>
        {screenshots.length || isLoading ? (
          (isLoading ? arr : screenshots)?.map((item, index) => (
            <div
              className="sct-img d-flex flex-column screen-card"
              key={index}
              onClick={() => handleModal(item?.url)}
              ref={screenshotCardRef}
            >
              <div className="image-wrapper">
                {isLoading ? (
                  <Skeleton count={1} className="sst-img" />
                ) : (
                  <Image
                    src={
                      showScreenshot === "TRUE"
                        ? item?.url
                        : "/images/screenshots/screenshots.jpg"
                    }
                    // src="/images/screenshots/screenshots.jpg"
                    alt="Screenshot"
                    className="sst-img"
                  />
                )}
              </div>
              <div className="sct-details d-flex flex-column">
                <div>
                  <h6 className="mb-0">
                    {isLoading ? (
                      <Skeleton count={1} className="w-25" />
                    ) : (
                      item?.emp_name
                    )}
                  </h6>
                </div>
                <div className="d-flex flex-row justify-content-between emp-id w-100">
                  <p className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.emp_code
                    )}
                  </p>
                  <h6 className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.emp_name
                    )}
                  </h6>
                  <p className={isLoading ? "skel-name" : ""}>
                    {isLoading ? (
                      <Skeleton
                        count={1}
                        className="w-75"
                        containerClassName="w-100"
                      />
                    ) : (
                      item?.description
                    )}
                  </p>
                </div>
                {/* <div className="emp-name">
                  <p>Taken by: Ajay Deb (Manager)</p>
                </div> */}
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex flex-row  w-100 no-data-row no-screenshots">
            <div className="no-data-image-wrapper">
              <Image src="/images/dashboard/folder_icon.svg" alt="Folder" />
            </div>
            <p>No Screenshots to show</p>
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
        className="screenshots-preview-carousel"
      >
        <Modal.Body>
          <Carousel
            activeIndex={Number(activeIndex)}
            onSelect={(selectedIndex: number) => setActiveIndex(selectedIndex)}
            prevIcon={<span className="carousel-control-prev-icon" />}
            nextIcon={<span className="carousel-control-next-icon" />}
            interval={null}
            indicators={false}
            keyboard={true}
          >
            {screenshots?.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                  <h5>{`${item?.emp_name} | ${item?.emp_code}`}</h5>

                  <img
                    className={`d-block w-100 ${showScreenshot === "TRUE" ? "" : "restrict-image"
                      }`}
                    src={
                      showScreenshot === "TRUE"
                        ? item?.url
                        : "/images/screenshots/screenshots.jpg"
                    }
                    alt={`Screenshot ${index}`}
                  />

                  <h5>{`${item?.description} `}</h5>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
      <div ref={observerTarget} className="observer-screenshots" style={{

      }}></div>
    </div>
  );
};

export default Screenshots;
