import React, { useState, useContext, useEffect, useRef } from "react";
import { Row, Col, Image, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "../../styles/dashboard.scss";
import { activityContext, activityProps, AccessInfo } from "../../pages/Dashboard";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setDate,
  setDeviceID,
  setFilterChosen,
  setFilterType,
  setOrganization,
  setDepartment,
  setEmployeeIds,
} from "../../features/date/dashboardSlice";
import { commonGetService } from "../../utils/properties";
import Skeleton from "react-loading-skeleton";
import RangePicker from "../CustomComponents/RangePicker/RangePicker";
import { useLocation } from "react-router-dom";
import { showToast } from "../../features/Toaster/toastslice";

type DateRange = [Date | null, Date | null];
interface Organization {
  name: string;
  depart_nos: number;
  emp_nos: number;
  image?: string;
  org_nos?: number;
}
interface Department {
  name: string;
  emp_nos: number;
  image?: string;
}
interface Employee {
  image: string;
  email: string;
  designation: string;
  employee_id: string;
  employee_name: string;
  employee_code: string;
  department: string;
  organization: string;
  device_id: string;
  reason: string;
  office_name?: string;
}
interface DayValue {
  year: number;
  month: number;
  day: number;
}

interface DayRange {
  from: DayValue | null;
  to: DayValue | null;
}

const blockInvalidDateChar = (e: React.KeyboardEvent<HTMLInputElement>) =>
  !/^[0-9/-]+$/.test(e.key) && e.key !== "Backspace" && e.preventDefault();

const Header = () => {
  const location = useLocation();
  const [selectedMonth, setSelectedMonth] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [dateFilter, setDateFilter] = useState<string>("today");
  const [dropShow, setDropShow] = useState<boolean>(false);
  const [organizationList, setOrganizationList] = useState<Organization[]>([]);
  const [departmentList, setDepartmentList] = useState<Department[]>([]);
  const [employeesList, setEmployeesList] = useState<Employee[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");
  const [imageSrc, setImageSrc] = useState<string>("");
  const [employeeCode, setEmployeeCode] = useState<string>("");
  const [minEmpData, setMinEmpData] = useState<number>(2);
  const [minOrgData, setMinOrgData] = useState<number>(2);
  const [minDeptData, setMinDeptData] = useState<number>(2);

  const [empNos, setEmpNos] = useState<number>(0);
  const [skelLoading, setSkelLoading] = useState<boolean>(false);

  const [fromDate, toDate] = dateRange; // Destructuring the dateRange state value
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const activityProps: activityProps | null = useContext(activityContext);
  const accessInfo: AccessInfo = useContext(activityContext)?.accessInfo as AccessInfo;

  const targetRef = useRef<HTMLDivElement | null>(null);
  const empListRef = useRef<HTMLDivElement | null>(null);
  const orgListRef = useRef<HTMLDivElement | null>(null);
  const deptListRef = useRef<HTMLDivElement | null>(null);
  const filterData = useAppSelector((state) => state.filterData);

  const defaultFrom: DayValue = {
    year: 2019,
    month: 4,
    day: 16,
  };

  const defaultTo: DayValue = {
    year: 2019,
    month: 4,
    day: 19,
  };

  const defaultValue: DayRange = {
    from: defaultFrom,
    to: defaultTo,
  };

  const [selectedDayRange, setSelectedDayRange] = useState<DayRange>({
    from: null,
    to: null,
  });
  const handleDayRangeChange = (newDayRange: DayRange) => {
    setSelectedDayRange(newDayRange);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    getAllUsersList();

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      targetRef.current &&
      e.target instanceof Node &&
      !targetRef.current.contains(e.target)
    ) {
      setDropShow(false);
      handleFilterDropdown();
      setSearchText("");
    }
  };

  const getAllUsersList = () => {
    setSkelLoading(true);
    commonGetService(`/api/1.0/dashboard/users/all`).then((res) => {
      setSkelLoading(false);
      if (res.status === 200) {
        res?.data?.organizations.sort(
          (orgA: Organization, orgB: Organization) => {
            let x = orgA?.name?.toLowerCase();
            let y = orgB?.name?.toLowerCase();
            if (x < y) {
              return -1;
            } else {
              return 1;
            }
            return 0;
          }
        );
        res?.data?.departments.sort((depA: Department, depB: Department) => {
          let x = depA?.name?.toLowerCase();
          let y = depB?.name?.toLowerCase();
          if (x < y) {
            return -1;
          } else {
            return 1;
          }
          return 0;
        });
        res?.data?.employees?.sort((empA: Employee, empB: Employee) => {
          let x = empA?.employee_name?.toLowerCase();
          let y = empB?.employee_name?.toLowerCase();
          if (x < y) {
            return -1;
          } else {
            return 1;
          }
          return 0;
        });
        res?.data?.organizations?.map(
          (val: Organization, ind: number, arr: Organization[]) => {
            if (val?.name === "All Organizations") {
              let allOrg: Organization[] = arr.splice(ind, 1);
              arr.unshift(allOrg[0]);
            }
          }
        );
        setOrganizationList(res?.data?.organizations);
        setDepartmentList(res?.data?.departments);
        setEmployeesList(res?.data?.employees);

        if (
          filterData?.filterType !== "organization" &&
          filterData?.filterType !== "department"
        ) {
          let emp: Employee[] = res?.data?.employees?.filter(
            (emp: Employee) => {
              if (emp.device_id === filterData.empDeviceId) {
                return emp.employee_name;
              }
            }
          );
          if (emp.length > 0) {
            setUserName(emp[0]?.employee_name);
            setDesignation(emp[0]?.designation);
            setImageSrc(emp[0]?.image);
            setEmployeeCode(emp[0]?.employee_code);
          }
        } else if (filterData?.filterType === "organization") {
          let empCount: Organization = res?.data?.organizations?.filter(
            (org: Organization) => org?.name === filterData?.organization
          )[0];

          setUserName(filterData?.organization);
          setEmpNos(empCount?.emp_nos);
          setImageSrc(empCount?.image ? empCount?.image : "");
          // console.log(res?.data.employees)
          dispatch(setEmployeeIds(res?.data.employees.filter((emp: Employee) =>
            filterData?.organization === "All Organizations" || emp?.office_name === filterData?.organization || emp?.department === filterData?.organization
          ).map((emp: Employee) => emp?.employee_id?.toString())));
        } else if (filterData?.filterType === "department") {
          let empCount: Organization = res?.data?.departments?.filter(
            (dep: Department) => dep?.name === filterData?.department
          )[0];
          setUserName(filterData?.department);
          setEmpNos(empCount?.emp_nos);
          setImageSrc(empCount?.image ? empCount?.image : "");
        }
      } else {
        dispatch(
          showToast({
            message: "Something went wrong in API.",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const getTimeRange = (): void => {
    setDateFilter(filterData?.filterChosen);

    if (filterData?.filterChosen === "month") {
      setSelectedMonth(new Date(filterData?.startDate));
    } else if (filterData?.filterChosen === "Range") {
      const dateToDayValue = (date: Date): DayValue => {
        return {
          year: date.getFullYear(),
          month: date.getMonth() + 1, // Months are 0-indexed in JavaScript
          day: date.getDate(),
        };
      };
      setSelectedDayRange({
        from: dateToDayValue(new Date(filterData?.startDate)),
        to: dateToDayValue(new Date(filterData?.endDate)),
      });
    }
  };

  const handleDate = (e: any) => {
    let today = new Date();
    let yesterday = moment().subtract(1, "day").toDate();
    if (e.target.id) {
      setDateFilter(e.target.id);
    }
    if (
      e.target.id === "today" ||
      e.target.id === "yesterday" ||
      e.target.id === "past_seven" ||
      e.target.id === "past_thirty"
    ) {
      setSelectedMonth(null);
      setDateRange([null, null]);
      setSelectedDayRange({ from: null, to: null });
    }
    if (e.target.id === "today") {
      dispatch(setDate({ startDate: today, endDate: today }));
      dispatch(setFilterChosen("today"));
    } else if (e.target.id === "yesterday") {
      dispatch(setDate({ startDate: yesterday, endDate: yesterday }));
      dispatch(setFilterChosen("yesterday"));
    } else if (e.target.id === "past_seven") {
      dispatch(
        setDate({
          startDate: moment().subtract(6, "days").toDate(),
          endDate: today,
        })
      );
      dispatch(setFilterChosen("past_seven"));
    } else if (e.target.id === "past_thirty") {
      dispatch(
        setDate({
          startDate: moment().subtract(29, "days").toDate(),
          endDate: today,
        })
      );
      dispatch(setFilterChosen("past_thirty"));
    }
  };

  const handleMonth = (date: Date) => {
    let today = new Date();
    let toDate = moment(date).endOf("month").toDate();
    setSelectedMonth(date);
    setDateRange([null, null]);
    dispatch(setDate({ startDate: date, endDate: toDate }));
    if (date === null) {
      setDateFilter("today");
      dispatch(setDate({ startDate: today, endDate: today }));

      setSelectedDayRange({ from: null, to: null });
      dispatch(setFilterChosen("today"));
    } else {
      setDateFilter("");
      dispatch(setFilterChosen("month"));
    }
  };

  const handleDateRange = (dateRange: DateRange) => {
    let [newFromDate, newToDate] = dateRange;
    let today = new Date();
    if (newFromDate === null && newToDate === null) {
      setDateFilter("today");
      dispatch(setFilterChosen("today"));
    } else {
      setDateFilter("");
      dispatch(setFilterChosen("Range"));
    }

    setDateRange(dateRange);
    setSelectedMonth(null);
    dispatch(
      setDate({ startDate: newFromDate || today, endDate: newToDate || today })
    );
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const handleUserSelect = (
    type: string,
    deviceId: string,
    empName: string,
    designation: string,
    count: number,
    empCode: string,
    image?: string,
    department?: string
  ) => {
    setDropShow(false);
    handleFilterDropdown();
    setSearchText("");
    setUserName(empName);
    setDesignation(designation);
    setImageSrc(image ? image : "");
    setEmpNos(count);
    setEmployeeCode(empCode);
    const filteredEmployeeIds: string[] = (employeesList || [])
      .filter((emp: Employee) =>
        empName === "All Organizations" || emp?.office_name === empName || emp?.department === empName
      )
      .map((emp: Employee) => emp?.employee_id.toString()); // Ensure a valid string
    dispatch(setEmployeeIds(filteredEmployeeIds));

    dispatch(setFilterType(type));
    dispatch(setDeviceID(deviceId));
    if (type === "department") {
      dispatch(setDepartment(empName));
      dispatch(setOrganization(""));
    } else if (type === "organization") {
      dispatch(setOrganization(empName));
      dispatch(setDepartment(""));
    } else {
      dispatch(setDepartment(""));
      dispatch(setOrganization(""));
    }
  };

  useEffect(() => {
    getTimeRange();
  }, [filterData]);

  const handleFilterDropdown = () => {
    setMinEmpData(2);
    setMinOrgData(2);
    setMinDeptData(2);

    if (empListRef.current) {
      empListRef.current.scrollTop = 0;
    }
    if (orgListRef.current) {
      orgListRef.current.scrollTop = 0;
    }
    if (deptListRef.current) {
      deptListRef.current.scrollTop = 0;
    }
  }

  const handleView = (type: string) => {
    if (type === 'emp') {
      if (empListRef.current) {
        empListRef.current.scrollTop = 0;
      }
      setMinEmpData(prevState => prevState === 2 ? 5 : 2)
      setMinOrgData(2);
      setMinDeptData(2);
    }
    else if (type === 'org') {
      if (orgListRef.current) {
        orgListRef.current.scrollTop = 0;
      }
      setMinOrgData(prevState => prevState === 2 ? 5 : 2)
      setMinEmpData(2);
      setMinDeptData(2);
    }
    else if (type === 'dept') {
      if (deptListRef.current) {
        deptListRef.current.scrollTop = 0;
      }
      setMinDeptData(prevState => prevState === 2 ? 5 : 2)
      setMinEmpData(2);
      setMinOrgData(2);
    }
  }

  return (
    <div className="w-100 dash-header">
      <Row className="m-0 filter-container">
        <Col className="p-0 position-relative" lg={5}>
          <div ref={targetRef} className="user-select-cont">
            <div
              className="user-select d-flex justify-content-between cursor-pointer flex-gap-dropdown"
              onClick={(e) => {
                setDropShow(!dropShow);
                handleFilterDropdown();
                e.stopPropagation();
              }}
            >
              <div className="swipe-logo">
                {imageSrc !== "" ? (
                  <Image
                    src={imageSrc}
                    alt="Swipewire logo"
                    className="profile-pic-employee"
                  />
                ) : (
                  <div className="img-avatar header-file-select d-flex justify-content-center align-items-center">
                    <p>{userName.charAt(0)}</p>
                  </div>
                )}
              </div>
              <div className="d-flex flex-column w-75">
                <h2>
                  {/* {filterData?.filterType !== "organization"
                    ? filterData?.filterType === "department"
                      ? filterData?.department
                      : `${userName}`
                    : "Swipewire Technology"} */}
                  {userName}
                </h2>
                <h4>
                  {filterData?.filterType === "organization" ||
                    filterData?.filterType === "department"
                    ? `${empNos} ${empNos ? (empNos === 1 ? "Employee" : "Employees") : ""
                    }`
                    // : `${designation}`}
                    : `${employeeCode} | ${designation}`}
                </h4>
              </div>
              <div className={`drop-arrow ${dropShow ? "rotate_arrow" : "rotate_arrow_back"}`}>
                <Image
                  src={"/images/dashboard/drop_down_arrow.svg"}
                  alt="Down arrow"
                />
              </div>
            </div>
            {/* All Users Drop down */}
            <div
              className={`position-absolute z-1 ${dropShow ? "d-block" : "d-none"
                }`}
            >
              <div className="user-drop-down d-flex flex-column">
                <div className="search-bar position-relative">
                  <Form.Control
                    type="text"
                    placeholder="Search emp name, ID, department etc..,"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Image
                    src="/images/dashboard/grey_search.svg"
                    alt="Search_Icon"
                    className="position-absolute"
                  />
                </div>

                <div className="d-flex flex-column">
                  <div className="d-flex flex-row justify-content-between align-items-center filter-title">
                    <h6>Employees ({employeesList?.length})</h6>
                    {employeesList?.length > 2 && <a onClick={() => { handleView('emp') }}>view all</a>}
                  </div>
                  {!skelLoading ? (
                    <div
                      className={`d-flex flex-column emp-cont ${minEmpData > 2 ? 'all-data' : ''}`}
                      ref={empListRef}
                    >
                      {employeesList
                        ?.filter((emp) => {
                          if (searchText.trim() === "") {
                            return emp;
                          } else if (
                            emp?.employee_name
                              .toLowerCase()
                              .includes(searchText.trim().toLocaleLowerCase()) ||
                            emp?.employee_code
                              .toLowerCase()
                              .includes(searchText.trim().toLowerCase()) ||
                            emp?.department
                              .toLowerCase()
                              .includes(searchText.trim().toLowerCase())
                          ) {
                            return emp;
                          }
                        })
                        // ?.filter((emp, i) => {
                        //   if (minEmpData == 2) {
                        //     return i < minEmpData;
                        //   }
                        //   else {
                        //     return emp;
                        //   }
                        // })
                        ?.map((res, ind) => (
                          <div className="d-flex flex-row align-items-center emp-details"
                            key={ind}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUserSelect(
                                "employee",
                                res?.device_id,
                                res?.employee_name,
                                res?.designation,
                                0,
                                res?.employee_code,
                                res?.image
                              );
                            }}>
                            <div>
                              {res?.image ? (
                                <Image src={res?.image} alt="Employee" />
                              ) : (
                                <div className="img-avatar d-flex justify-content-center align-items-center">
                                  <p>{res?.employee_name?.charAt(0)}</p>
                                </div>
                              )}
                            </div>
                            <div className="d-flex flex-column">
                              <p className="emp-name">{res?.employee_name}</p>
                              <p className="emp-des">{`${res?.employee_code} | ${res?.designation}`}</p>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="d-flex flex-column emp-cont">
                      <div className="d-flex flex-row align-items-center emp-details">
                        <div>
                          <Skeleton
                            className="img-skel"
                            count={1}
                            circle={true}
                          />
                        </div>
                        <div className="d-flex flex-column w-100">
                          <p className="emp-name w-75">
                            <Skeleton count={1} />
                          </p>
                          <p className="emp-des w-75">
                            <Skeleton count={1} />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex flex-column">
                  <div className="d-flex flex-row justify-content-between align-items-center filter-title">
                    <h6>Organization ({organizationList?.length})</h6>
                    {organizationList?.length > 2 && <a onClick={() => { handleView('org') }}>view all</a>}
                  </div>
                  {!skelLoading ? (
                    <div className={`d-flex flex-column emp-cont ${minOrgData > 2 ? 'all-data' : ''}`} ref={orgListRef}>
                      {
                        organizationList
                          ?.filter((org) => {
                            if (searchText.trim() === "") {
                              return org;
                            } else if (
                              org?.name
                                .toLowerCase()
                                .includes(searchText.trim().toLocaleLowerCase())
                            ) {
                              return org;
                            }
                          })
                          // ?.filter((org, i) => {
                          //   if (minOrgData == 2) {
                          //     return i < minOrgData;
                          //   }
                          //   else {
                          //     return org;
                          //   }
                          // })
                          ?.map((res, ind) => (
                            <div className="d-flex flex-row align-items-center emp-details"
                              key={ind}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUserSelect(
                                  "organization",
                                  "",
                                  res?.name,
                                  "",
                                  res?.emp_nos,
                                  "",
                                  res?.image
                                );
                              }}>
                              <div>
                                {res?.image ? (
                                  <Image src={res?.image} alt="Swipewire Logo" />
                                ) : (
                                  <div className="img-avatar d-flex justify-content-center align-items-center">
                                    <p>{res?.name?.charAt(0)}</p>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex flex-column">
                                <p className="emp-name">{res?.name}</p>
                                <p className="emp-des">
                                  {`${res?.emp_nos} Employee | ${res?.depart_nos
                                    } Department ${res?.name === "All Organizations"
                                      ? `| ${res?.org_nos} Organizations`
                                      : ""
                                    }`}
                                </p>
                              </div>
                            </div>
                          ))}
                    </div>
                  ) : (
                    <div className="d-flex flex-column emp-cont">
                      <div className="d-flex flex-row align-items-center emp-details">
                        <div>
                          <Skeleton
                            className="img-skel"
                            count={1}
                            circle={true}
                          />
                        </div>
                        <div className="d-flex flex-column w-100">
                          <p className="emp-name w-75">
                            <Skeleton count={1} />
                          </p>
                          <p className="emp-des w-75">
                            <Skeleton count={1} />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex flex-column">
                  <div className="d-flex flex-row justify-content-between align-items-center filter-title">
                    <h6>Departments ({departmentList?.length})</h6>
                    {departmentList?.length > 2 && <a onClick={() => { handleView('dept') }}>view all</a>}
                  </div>
                  {!skelLoading ? (
                    <div className={`d-flex flex-column emp-cont dep-box ${minDeptData > 2 ? 'all-data' : ''}`} ref={deptListRef}>
                      {
                        departmentList
                          ?.filter((dep) => {
                            if (searchText.trim() === "") {
                              return dep;
                            } else if (
                              dep?.name
                                .toLowerCase()
                                .includes(searchText.trim().toLocaleLowerCase())
                            ) {
                              return dep;
                            }
                          })
                          // ?.filter((dep, i) => {
                          //   if (minDeptData == 2) {
                          //     return i < minDeptData;
                          //   }
                          //   else {
                          //     return dep;
                          //   }
                          // })
                          ?.map((res, ind) => (
                            <div className="d-flex flex-row align-items-center emp-details"
                              key={ind}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUserSelect(
                                  "department",
                                  "",
                                  res?.name,
                                  "",
                                  res?.emp_nos,
                                  "",
                                  res?.image
                                );
                              }}>
                              <div>
                                {res?.image ? (
                                  <Image src={res?.image} alt="Employee" />
                                ) : (
                                  <div className="img-avatar d-flex justify-content-center align-items-center dep-atr">
                                    <p>{res?.name?.charAt(0)}</p>
                                  </div>
                                )}
                              </div>
                              <div className="d-flex flex-column">
                                <p className="emp-name">{res?.name}</p>
                                <p className="emp-des">{res?.emp_nos} Employee</p>
                              </div>
                            </div>
                          ))}
                    </div>
                  ) : (
                    <div className="d-flex flex-column emp-cont">
                      <div className="d-flex flex-row align-items-center emp-details">
                        <div>
                          <Skeleton
                            className="img-skel"
                            count={1}
                            circle={true}
                          />
                        </div>
                        <div className="d-flex flex-column w-100">
                          <p className="emp-name w-75">
                            <Skeleton count={1} />
                          </p>
                          <p className="emp-des w-75">
                            <Skeleton count={1} />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col
          className="p-0 d-flex justify-content-end align-items-baseline day-range"
          lg={7}
          onClick={(e) => handleDate(e)}
        >
          <div
            id="today"
            className={
              dateFilter === "today"
                ? "active-filter cursor-pointer"
                : "cursor-pointer"
            }
          >
            Today
          </div>
          <div
            id="yesterday"
            className={
              dateFilter === "yesterday"
                ? "active-filter cursor-pointer"
                : "cursor-pointer"
            }
          >
            Yesterday
          </div>
          <div
            id="past_seven"
            className={
              dateFilter === "past_seven"
                ? "active-filter cursor-pointer"
                : "cursor-pointer"
            }
          >
            Past 7 days
          </div>
          <div
            id="past_thirty"
            className={
              dateFilter === "past_thirty"
                ? "active-filter cursor-pointer"
                : "cursor-pointer"
            }
          >
            Past 30 days
          </div>
          <div
            className="month-st position-relative"
            onClick={(e) => { setIsOpen(!isOpen) }}
          >
            <Image
              src="/images/dashboard/calendar_icon.svg"
              alt="Calendar"
              className="position-absolute cal-img"
            />
            <DatePicker
              selected={selectedMonth}
              open={isOpen}
              onClickOutside={() => setIsOpen(false)}
              closeOnScroll={(e) => {
                setIsOpen(false); // Debug log to ensure scroll is captured
                return true;
              }}
              // onMonthMouseLeave={() => {}}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="d-flex flex-row justify-content-between align-items-center month-header">
                  <div>
                    <button
                      className="button-left"
                      onClick={(e) => { e.stopPropagation(); decreaseMonth() }}
                      disabled={prevMonthButtonDisabled}
                    >
                      <Image src="/images/dashboard/LeftArrow.svg" />
                    </button>
                  </div>
                  <div className="mt-1">
                    <h6>
                      {`${monthNames[date.getMonth()]} ${date.getFullYear()}`}
                    </h6>
                  </div>
                  <div>
                    {/* Format the date object */}
                    <button
                      className="button-right"
                      onClick={(e) => { e.stopPropagation(); increaseMonth() }}
                      disabled={nextMonthButtonDisabled}
                    >
                      <Image src="/images/dashboard/rightArrow.svg" />
                    </button>
                  </div>
                </div>
              )}
              onChange={(date: Date) => {
                handleMonth(date);
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Select Month"
              // id="month"
              onKeyDown={blockInvalidDateChar}
              maxDate={new Date()}
            />
          </div>
          <div
            className="date-st position-relative"
          // style={{ width: "20.75rem", height: "22/5rem" }}
          >
            {/* <Image
              src="/images/dashboard/calendar_icon.svg"
              alt="Calendar"
              className="position-absolute cal-img"
            />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selectsRange={true}
              startDate={fromDate}
              endDate={toDate}
              onChange={(date: DateRange) => {
                handleDateRange(date);
              }}
              isClearable={true}
              placeholderText="Filter by date range"
              id="date_range"
              maxDate={new Date()}
              onKeyDown={blockInvalidDateChar}
            /> */}

            <RangePicker value={selectedDayRange} onSelect={handleDateRange} />
          </div>
        </Col>
      </Row>
      {location.pathname === "/dashboard" && accessInfo["Activity Time"] && (
        <Row className="m-0 time-ctr">
          <Col className="p-0 d-flex align-items-center total-time">
            <h3>TOTAL TIME TRACKED</h3>
            {/* <p>5400 hrs 30 mins</p> */}
            <p>
              {activityProps?.isLoading ? (
                <Skeleton className="time-skel" count={1} />
              ) : (
                activityProps?.activityDetails?.total_time
              )}
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Header;
