import { createSlice , PayloadAction } from "@reduxjs/toolkit";


interface InitialState {
isPicUpdate : boolean
}

const initialState: InitialState = {
    
    isPicUpdate: false,
    
};

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setProfilePic: (state, action: PayloadAction<{ isPicUpdate: boolean }>) => {
           
            state.isPicUpdate = action.payload.isPicUpdate ;
            
        },
    },
});

export const { setProfilePic } = settingSlice.actions;
export default settingSlice.reducer;