import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Dashboard/Header";
import NavbarLayout from "../components/NavbarLayout";
import ScreenshotsComponent from "../components/Screenshots/Screenshots";
import "../styles/screenshots.scss"

const Screenshots = () => {
    const pageRef = useRef<HTMLDivElement>(null);
    const [divHeight, setDivHeight] = useState<number>(0);

    useEffect(() => {
        const updateHeight = () => {
            if (pageRef.current) {
                setDivHeight(pageRef.current.offsetHeight);
            }
        };

        // Initial measurement
        updateHeight();




    }, []);

    return (
        <div className="screenshots-wrapper-page">
            <NavbarLayout>
                <Header />
                <div className="data-container" style={{ flex: 1, paddingBottom: 0 }} ref={pageRef}>
                    <ScreenshotsComponent height={divHeight} />

                </div>
            </NavbarLayout>
        </div>
    );
};

export default Screenshots;
