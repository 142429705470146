import React, { useState, useEffect } from "react";
import { Modal, Button, Image, Form } from "react-bootstrap";
import "../../styles/Managers/addManager.scss";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  setModalShow,
  setIsManagerCreate,
  setEditUserDetails,
  setIsEdit,
} from "../../features/addManager/addManagerSlice";
import {
  commonGetService,
  commonPostservice,
  commonPutService,
} from "../../utils/properties";
import { ManagerData as UserData, RolesData } from "../../pages/ManagerList";
import { showToast } from "../../features/Toaster/toastslice";
import { useLocation } from "react-router";

type ValidateFunction = () => boolean;

interface SelectedRole {
  role: string;
  role_id: string;
}

const AddManager = () => {
  const [rolesList, setRolesList] = useState<RolesData[]>([]);
  const [selectedRole, setSelectedRole] = useState<SelectedRole | null>(null);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [empDropOpen, setEmpDropOpen] = useState<boolean>(false);
  const [roleDropOpen, setRoleDropOpen] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const [isEditClicked, setIsEditClicked] = useState<string>("Add");
  // const [userData, setUserData] = useState<UserData[]>([]);
  // const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  // const [searchText, setSearchText] = useState<string>("");

  const addManager = useAppSelector((state) => state.addManager);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const currentUserId = location.state?.state;

  useEffect(() => {
    if (addManager?.isEdit) {
      getEditUserDetails();
      setIsEditClicked("Update");
    }
    // getUsersList();
    getRolesList();
  }, []);

  // const getUsersList = () => {
  //     commonGetService(`/user/all/user`).then(res => {
  //         if (res.message === "Success") {
  //             setUserData(res?.data);
  //         }
  //     })
  // }

  const getEditUserDetails = () => {
    setUserEmail(addManager?.editUserDetails?.email);
    setUserPassword(addManager?.editUserDetails?.password);
    setSelectedRole({
      role: addManager?.editUserDetails?.role,
      role_id: addManager?.editUserDetails?.role_id,
    });
  };

  const getRolesList = () => {
    commonGetService(`/api/1.0/role/all`).then((res) => {
      if (res.status === 200) {
        setRolesList(res?.data);
      } else {
        dispatch(
          showToast({
            message: "Something went wrong in API",
            status: "retry",
            visible: true,
          })
        );
      }
    });
  };

  const handleOpen = (type: string) => {
    if (type === "role") {
      setRoleDropOpen(!roleDropOpen);
      setEmpDropOpen(false);
    } else {
      setEmpDropOpen(!empDropOpen);
      setRoleDropOpen(false);
    }
  };

  const handleModalClose = () => {
    if (addManager?.isEdit) {
      dispatch(
        setEditUserDetails({
          status: "",
          id: "",
          email: "",
          password: "",
          role_id: "",
          sr_no: "",
          created_date: "",
          updated_date: "",
          role: "",
          employee_code: "",
        })
      );
      dispatch(setIsEdit(false));
    }
    dispatch(setModalShow(false));
  };

  // const handleSelectUser = (user: UserData) => {
  //     setSelectedUser(user);
  // }

  const handleSelectRole = (roleData: RolesData) => {
    setSelectedRole({
      role: roleData?.role_name,
      role_id: roleData?.id,
    });
    setRoleDropOpen(!roleDropOpen);
  };

  const handleGeneratePassword = () => {
    setIsSave(true);
    commonGetService(`/api/1.0/manager/generate/password?length=12`).then(
      (res) => {
        setIsSave(false);
        if (res.status === 200) {
          setUserPassword(res?.data?.password);
        } else {
          dispatch(
            showToast({
              message: "Something went wrong in API",
              status: "retry",
              visible: true,
            })
          );
        }
      }
    );
  };

  const handleValidate: ValidateFunction = () => {
    // if (!selectedUser) {
    //     alert("Please select the user");
    //     return false;
    // }
    // else if (!selectedRole) {
    //     alert("Please select the role");
    //     return false;
    // }
    console.log(selectedRole);

    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&()])[A-Za-z\d@$!%*?&()]{12,}$/;

    if (userEmail.trim() === "") {
      dispatch(
        showToast({
          message: "Please enter the Email ID",
          status: "retry",
          visible: true,
        })
      );
      return false;
    } else if (!emailRegex.test(userEmail)) {
      dispatch(
        showToast({
          message: "Please enter the valid Email ID",
          status: "retry",
          visible: true,
        })
      );
      return false;
    } else if (userPassword.trim() === "") {
      dispatch(
        showToast({
          message: "Please enter the password",
          status: "retry",
          visible: true,
        })
      );
      return false;
    } else if (!passwordRegex.test(userPassword)) {
      dispatch(
        showToast({
          message: `Your password must be at least 12 characters long and include at least one uppercase letter,
                     one lowercase letter, one number, and one special character (such as @, $, !, %, *, ?, &)`,
          status: "retry",
          visible: true,
        })
      );
      return false;
    } else if (!selectedRole?.role_id) {
      dispatch(
        showToast({
          message: "Please select the Role",
          status: "retry",
          visible: true,
        })
      );
      return false;
    }

    return true;
  };

  const handleAddManager = () => {
    if (handleValidate()) {
      let params = {
        email: userEmail,
        password: userPassword,
        role_id: selectedRole?.role_id,
      };
      setIsSave(true);
      if (isEditClicked === "Add") {
        commonPostservice(`/api/1.0/manager/add`, params).then((res) => {
          setIsSave(false);
          if (res.status === 201) {
            dispatch(setIsManagerCreate(!addManager?.isManagerCreate));
            handleModalClose();
          } else if (res.status === 208) {
            dispatch(
              showToast({
                message: "User Already Exists",
                status: "retry",
                visible: true,
              })
            );
          } else {
            dispatch(
              showToast({
                message: "Something went wrong in API",
                status: "retry",
                visible: true,
              })
            );
          }
        });
      } else {
        commonPutService(`/api/1.0/manager/update`, params).then((res) => {
          setIsSave(false);
          if (res.status === 200) {
            dispatch(setIsManagerCreate(!addManager?.isManagerCreate));
            handleModalClose();
          } else {
            dispatch(
              showToast({
                message: "Something went wrong in API",
                status: "retry",
                visible: true,
              })
            );
          }
        });
      }
    }
  };

  return (
    <div>
      <Modal
        show={addManager?.modalShow}
        onHide={() => handleModalClose()}
        centered
        size="lg"
        className="add-mngr-pop"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEditClicked} Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column">
            {/* <div className='d-flex flex-column'>
                            <div className='drop-title'>
                                <h5>Employees</h5>
                            </div>
                            <div className={`d-flex flex-row justify-content-between align-items-center drop-input ${empDropOpen ? 'brd-cls' : ''}`} onClick={() => handleOpen("emp")}>
                                <div>
                                    {selectedUser ?
                                        //This code is for selected employees
                                        <div className='d-flex flex-row align-items-center slct-emp'>
                                            {selectedUser?.image ?
                                                <div>
                                                    <Image src={selectedUser?.image} alt='Employee' roundedCircle />
                                                </div> :
                                                <div className="img-avatar d-flex justify-content-center align-items-center">
                                                    <p>{selectedUser?.employee_name?.charAt(0)}</p>
                                                </div>
                                            }
                                            <div className='d-flex flex-column ms-2 emp-name active'>
                                                <p>{selectedUser?.employee_name}</p>
                                                <p>{selectedUser?.employee_code} <span>|</span> {selectedUser?.email}</p>
                                            </div>
                                        </div>
                                        :
                                        <p className='un-emp'>Select Employee</p>
                                    }
                                </div>
                                <div>
                                    <Image src={`/images/roles/${!empDropOpen ? 'role_down_arrow' : 'role_up_arrow'}.svg`} alt='Down' />
                                </div>
                            </div>

                            Dropdown body for Employees
                            <div className={`dropdown-body d-flex flex-column ${empDropOpen ? 'emp-drop-open' : ""}`}>
                                <div className='emp-search-bar'>
                                    <Form.Control type='text' placeholder='Search using Name, ID and Department' value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    <Image src='/images/roles/black_search_icon.svg' alt='Search' />
                                </div>
                                <div className='scroll-cont common-scroll-stl'>
                                    <div className='d-flex flex-column emp-list-cont'>
                                        {userData?.filter(res => {
                                            if (searchText === "") {
                                                return res;
                                            }
                                            else if (res?.employee_name.trim().toLowerCase().includes(searchText.trim().toLowerCase()) ||
                                                res?.employee_code.trim().toLowerCase().includes(searchText.trim().toLowerCase()) ||
                                                res?.department.trim().toLowerCase().includes(searchText.trim().toLowerCase())) {
                                                return res;
                                            }
                                        })?.map((user, ind) => (
                                            <div className={`d-flex flex-row align-items-center emp-dts-cont slct-emp cursor-pointer
                                            ${selectedUser?.employee_id === user?.employee_id ? 'active-emp' : ''}`} key={ind} onClick={() => handleSelectUser(user)}
                                            >
                                                {user?.image ?
                                                    <div>
                                                        <Image src={user?.image} alt='Employee' roundedCircle />
                                                    </div> :
                                                    <div className="img-avatar d-flex justify-content-center align-items-center">
                                                        <p>{user?.employee_name?.charAt(0)}</p>
                                                    </div>
                                                }
                                                <div className='d-flex flex-column ms-2 emp-name'>
                                                    <p>{user?.employee_name}</p>
                                                    <p>{user?.employee_code} <span>|</span> {user?.email}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <Form>
              <Form.Group className="email-grp">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="userEmail"
                  type="email"
                  value={userEmail}
                  placeholder="Enter Email Address"
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="email-grp pass-grp">
                <Form.Label>Password</Form.Label>
                <div className="d-flex justify-content-between">
                  <Form.Control
                    name="userPassword"
                    type="text"
                    value={userPassword}
                    placeholder="Generate Password"
                    className="pass-input"
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                  <Button
                    disabled={isSave}
                    onClick={() => handleGeneratePassword()}
                  >
                    Generate
                  </Button>
                </div>
              </Form.Group>
            </Form>
            <div className="d-flex flex-column pass-grp">
              <div className="drop-title">
                <h5>Define Role</h5>
              </div>
              <div
                className={`d-flex flex-row justify-content-between align-items-center drop-input ${
                  roleDropOpen ? "brd-cls" : ""
                }`}
                onClick={() => {
                  handleOpen("role");
                }}
              >
                <p
                  className={`un-emp text-capitalize ${
                    !selectedRole ? "slct" : ""
                  }`}
                >
                  {selectedRole ? selectedRole?.role : "Select Role"}
                </p>
                <div>
                  {currentUserId !== addManager.editUserDetails.email && (
                    <Image
                      src={`/images/roles/${
                        !roleDropOpen ? "role_down_arrow" : "role_up_arrow"
                      }.svg`}
                      alt="Down"
                    />
                  )}
                </div>
              </div>

              {/* Dropdown body for Roles */}
              <div
                className={`dropdown-body d-flex flex-column ${
                  currentUserId !== addManager.editUserDetails.email &&
                  roleDropOpen
                    ? "role-drop-open"
                    : ""
                }`}
              >
                {rolesList?.map((role, ind) => (
                  <div
                    className={`role-cont text-capitalize cursor-pointer ${
                      selectedRole?.role === role?.role_name
                        ? "active-role"
                        : ""
                    }`}
                    key={ind}
                    onClick={() => handleSelectRole(role)}
                  >
                    {role?.role_name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cnl-btn"
            variant="outline-secondary"
            disabled={isSave}
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button
            className="add-btn"
            disabled={isSave}
            onClick={() => handleAddManager()}
          >
            {isEditClicked}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddManager;
