import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Offcanvas,
  Accordion,
} from "react-bootstrap";
import "../styles/navbar.scss";
import ProfileBadge from "./CustomComponents/ProfileBadge/ProfileBadge";
import NotificationDropdown from "./CustomComponents/NotificationBell/NotificationDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  setDate,
  setDeviceID,
  setFilterChosen,
  setFilterType,
  setDepartment,
  setOrganization,
  setEmployeeIds,
} from "../features/date/dashboardSlice";
import {
  setModalShow,
  setManagerDropShow,
  setMoreDropShow,
} from "../features/addManager/addManagerSlice";
import { commonGetService } from "../utils/properties";
import { AccessInfo, AccessDetails } from "../pages/Dashboard";

interface layoutProps {
  children: React.ReactNode;
}
export interface userDetails {
  name: string;
  picture: string;
  employee_id: string;
  role: string;
}

interface tabHovered {
  dashboardHover: boolean;
  settingHover: boolean;
  projectHover: boolean;
  managerHover: boolean;
  addManagerHover: boolean;
  managerListHover: boolean;

  reportHover: boolean;
  screenshotHover: boolean;
  moreHover: boolean;
  roleHover: boolean;
}

interface FeatureDetails {
  features: AccessDetails[],
  status: boolean
}


const NavbarLayout = (props: layoutProps) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const addManager = useAppSelector((state) => state.addManager);
  const dispatch = useAppDispatch();

  const [navShow, setNavShow] = useState<boolean>(false);
  // const [projectHover, setProjectHover] = useState<boolean>(false);

  // const [mgrHover, setMgrHover] = useState<boolean>(false);
  // const [reportHover, setReportHover] = useState<boolean>(false);
  // const [screenshotHover, setScreenshotHover] = useState<boolean>(false);
  // const [moreHover, setMoreHover] = useState<boolean>(false);
  const [isManagerDropdown, setIsManagerDropdown] = useState<boolean>(false);
  const [isMoreClicked, setIsMoreClicked] = useState<boolean>(false);

  // const [settingHover, setSettingHover] = useState<boolean>(false);
  // const [dashboardHover, setDashboardHover] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [currentUserEmailId, setCurrentUserEmailId] = useState<string>("");
  const [activeKey, setActiveKey] = useState<any>("");
  const [accessInfo, setAccessInfo] = useState<AccessInfo>({});
  const [addManagerShow, setAddManagerShow] = useState<boolean>(false);
  const [accountDetailsShow, setAccountDetailsShow] = useState<boolean>(false);


  // const [isManagerListHover, setIsManagerListHover] = useState<boolean>(false);
  // const [isAddManagerHover, setIsAddManagerHover] = useState<boolean>(false);
  // const [isRolesHover, setIsRolesHover] = useState<boolean>(false);

  const [hoveredState, setHoveredState] = useState<tabHovered>({
    dashboardHover: false,
    settingHover: false,
    projectHover: false,
    managerHover: false,
    addManagerHover: false,
    managerListHover: false,
    reportHover: false,
    screenshotHover: false,
    moreHover: false,
    roleHover: false
  })

  const [currentProfileDetail, setCurrentProfileDetail] = useState<userDetails>(
    {
      name: "",
      picture: "",
      employee_id: "",
      role: "",
    }
  );

  // const [managerDropShow, setManagerDropShow] = useState<boolean>(false);
  const updateProfilePic = useAppSelector((state) => state.setting.isPicUpdate);

  const { dashboardHover, settingHover, projectHover, managerHover, addManagerHover, managerListHover, reportHover, screenshotHover, moreHover, roleHover } = hoveredState
  useEffect(() => {
    const handleScroll = () => {
      if (addManager?.managerDropShow) {
        dispatch(setManagerDropShow(false));
      }
      if (addManager?.moreDropShow) {
        dispatch(setMoreDropShow(false));
      }
    };
    // Add scroll event listener
    window.addEventListener("wheel", handleScroll);
    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [addManager?.managerDropShow, addManager?.moreDropShow]);

  useEffect(() => {
    getProfileData();
  }, [updateProfilePic]);

  useEffect(() => {
    if (
      location.pathname.includes("managerList")
    ) {
      setActiveKey("0"); // Keep the first accordion item open
    } else if (
      location.pathname.includes("roles")

    ) {
      setActiveKey("1");
    } else {
      setActiveKey(""); // Setting empty string instead of null
    }
  }, [location.pathname]);

  useEffect(() => {
    if (localStorage.getItem("roleDetails")) {
      let roleDetails = JSON.parse(localStorage.getItem("roleDetails") as string);
      let accessInfo: AccessInfo = {}
      Object.entries(roleDetails?.features as { [key: string]: FeatureDetails })?.map(([key, value]) => {
        if (key === "manager" && value?.status) {
          value?.features?.forEach(res => {
            if (res?.name === "Add Manager" && res.status) {
              setAddManagerShow(res.status);
            }
          })
        }
        else if (key === "setting" && value?.status) {
          value?.features?.forEach(res => {
            if (res?.name === "General Setting" && res.status) {
              setAccountDetailsShow(res.status);
            }
          })
        }
        accessInfo[key] = value?.status;
      })
      setAccessInfo(accessInfo);
    }
  }, [])

  const getProfileData = () => {
    commonGetService(`/api/1.0/setting/profile/id`).then((res) => {
      if (res.status === 200) {
        // setCurrentProfileDetail({
        //     name: res.data.name,
        //     picture: res.data.profile_picture_path,
        //     role: res.data.role_name,
        //     employee_id: res.data.emp_code,
        // })

        setCurrentUserEmailId(res.data.email);

        setCurrentProfileDetail(() => ({
          name: res.data.name,
          picture: res.data.profile_picture_path,
          role: res.data.role_name,
          employee_id: res.data.emp_code,
        }));
      }
    });
  };

  useEffect(() => {
    getProfileData();
  }, [updateProfilePic]);

  useEffect(() => {
    if (
      location.pathname.includes("managerList")
    ) {
      setActiveKey("0"); // Keep the first accordion item open
    } else if (
      location.pathname.includes("roles")

    ) {
      setActiveKey("1");
    } else {
      setActiveKey(""); // Setting empty string instead of null
    }
  }, [location.pathname]);

  const handleNavClose = () => {
    setNavShow(false);
    if (location.pathname.includes('managerList')) {
      setActiveKey('0');
    } else if (location.pathname.includes('roles')) {
      setActiveKey('1');
    } else {
      setActiveKey('');
    }
  };

  const handleTab = (tab: string) => {
    setSelectedTab(tab);
    let today = new Date();
    dispatch(setDeviceID(""));
    dispatch(setDate({ startDate: today, endDate: today }));
    dispatch(setFilterChosen("today"));
    dispatch(setFilterType("organization"));
    dispatch(setDepartment(""));
    dispatch(setEmployeeIds([]));
    dispatch(setOrganization("All Organizations"));
    if (tab !== "managerList") {
      dispatch(setManagerDropShow(false));
    }
    if (tab !== "roles") {
      dispatch(setMoreDropShow(false));
    }

    if (tab === "dashboard") {
      Navigate("/dashboard");
    } else if (tab === "screenshots") {
      Navigate("/screenshots");
    } else if (tab === "setting") {
      Navigate("/settings");
      setSelectedTab("settings");
    } else if (tab === "managerList") {
      Navigate("/managerList", { state: { state: currentUserEmailId } });
    } else if (tab === "roles") {
      Navigate("/roles");
    } else if (tab === "reports") {
      Navigate("/reports");
    }
  };

  const handleSelect = (key: any) => {
    setActiveKey(key);
  };

  const handleMouseEnter = (tab: string) => {
    setHoveredState({
      dashboardHover: false,
      settingHover: false,
      projectHover: false,
      managerHover: false,
      addManagerHover: false,
      managerListHover: false,
      reportHover: false,
      screenshotHover: false,
      moreHover: false,
      roleHover: false,
      [`${tab}Hover`]: true, // Dynamically set the hovered tab
    });
  };

  const handleMouseLeave = () => {
    setHoveredState({
      dashboardHover: false,
      settingHover: false,
      projectHover: false,
      managerHover: false,
      addManagerHover: false,
      managerListHover: false,
      reportHover: false,
      screenshotHover: false,
      moreHover: false,
      roleHover: false
    });
  }
  const handleLogout = () => {
    document.cookie =
      "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    window.location.href = "/";
    // navigate("/")
  };
  return (
    <Container fluid className="w-100 vh-100 d-flex flex-column m-0 p-0">
      <Row className="nav-bar justify-content-between align-items-center m-0 nav-menu-max position-fixed w-100 top-0 z-3">
        <Col
          className="p-0 menu-btn cursor-pointer"
          md="auto"
          onClick={() => setNavShow(true)}
        >
          <Image src="/images/dashboard/menu_button.svg" alt="Menu button" />
        </Col>
        <Col className="p-0 cursor-pointer" lg={2} md>
          <Image
            src="/images/dashboard/timelyze_new_logo_tab.svg"
            className="brand-logo"
          />
        </Col>
        <Col
          className="d-flex align-items-center cursor-pointer justify-content-center nav-menu p-0 "
          lg={7}
        >
          {accessInfo?.dashboard &&
            <div
              className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("dashboard") ? "active-tab" : ""
                }`}
              onClick={() => {
                !location.pathname.includes("dashboard") &&
                  handleTab("dashboard");
                dispatch(setManagerDropShow(false));
              }}
            >
              <div>
                <Image
                  src="/images/dashboard/dashboard_icon.svg"
                  alt="Dashboard"
                />
              </div>
              <p>Dashboard</p>
            </div>
          }
          {accessInfo?.projects &&
            <div
              className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("projects") ? "active-tab" : ""
                }`}
            >
              {/* <div> */}
              <Image src="/images/dashboard/project_icon.svg" alt="Projects" />
              {/* </div> */}
              <p>Projects</p>
            </div>
          }
          {/* <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("manualTime") ? "active-tab" : " "
              }`}
            onClick={() => handleTab("manualTime")}
          >
            <div>
              <Image
                src="/images/dashboard/clock_icon.svg"
                alt="Manural Time"
              />
            </div>
            <p>Manual Time</p>
          </div> */}
          {/* <div
            className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("addManager") ? "active-tab" : " "
              }`}
            onClick={() => handleTab("addManager")}
          >
            <div>
              <Image src="/images/dashboard/add_icon.svg" alt="Add Manager" />
            </div>
            <p>Add Manager</p>
          </div> */}
          {accessInfo?.manager &&
            <div
              className={`drop-mngr d-flex align-items-center ${location.pathname.includes("managerList") ? "active-tab" : " "
                }`}
              onClick={() => {
                dispatch(setManagerDropShow(!addManager?.managerDropShow));
              }}
            >
              <DropdownButton
                id="mngr-dropdown"
                title="Managers"
                onToggle={() => {
                  dispatch(setManagerDropShow(!addManager?.managerDropShow));
                }}
                show={addManager?.managerDropShow}
              >
                <div>
                  {addManagerShow &&
                    <Dropdown.Item onClick={() => dispatch(setModalShow(true))}>
                      Add Manager
                    </Dropdown.Item>
                  }
                  <Dropdown.Item
                    onClick={() => {
                      handleTab("managerList");
                    }}
                  >
                    Manager List
                  </Dropdown.Item>
                </div>
              </DropdownButton>
              <div>
                <Image
                  src={`/images/dashboard/more_down_arrow.svg`}
                  alt="Down Arrow"
                  className={`cursor-pointer ms-2 ${addManager?.managerDropShow?"rotate_arrow":"rotate_arrow_back"} `}
                />
              </div>
            </div>
          }
          {accessInfo?.reports &&
            <div
              className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("reports") ? "active-tab" : " "
                }`}
              onClick={() =>
                !location.pathname.includes("reports") && handleTab("reports")
              }
            >
              <div>
                <Image src="/images/dashboard/report_icon.svg" alt="Reports" />
              </div>
              <p>Reports</p>
            </div>
          }
          {accessInfo?.screenshot &&
            <div
              className={`d-flex align-items-center cursor-pointer ${location.pathname.includes("screenshots") ? "active-tab" : ""
                }`}
              onClick={() =>
                !location.pathname.includes("screenshots") &&
                handleTab("screenshots")
              }
            >
              <div>
                <Image
                  src={`/images/dashboard/screenshot_logo.svg`}
                  alt="screenshots"
                />
              </div>
              <p>Screenshots</p>
            </div>
          }
          {accessInfo?.roles &&
            <div
              className={`drop-more d-flex align-items-center cursor-pointer ${location.pathname.includes("roles") ? "active-tab" : " "
                }`}
              onClick={() => {
                dispatch(setMoreDropShow(!addManager?.moreDropShow));
              }}
            >
              <DropdownButton
                id="role-dropdown"
                title="More"
                onToggle={() =>
                  dispatch(setMoreDropShow(!addManager?.moreDropShow))
                }
                show={addManager?.moreDropShow}
              >
                <Dropdown.Item onClick={() => handleTab("roles")}>
                  Roles
                </Dropdown.Item>
              </DropdownButton>
              <div>
                <Image
                  src={`/images/dashboard/more_down_arrow.svg`}
                  alt="Down Arrow"
                  className={`cursor-pointer ms-2 ${addManager?.moreDropShow?"rotate_arrow":"rotate_arrow_back"} `}
                />
              </div>
            </div>
          }
        </Col>
        <Col
          className="p-0 d-flex align-items-center cursor-pointer nav-search"
          md
          lg={3}
        >
          <div className="ms-auto">
            <Image
              src="/images/dashboard/search_icon.svg"
              alt="Search"
              className="search-icon"
            />
          </div>
          {/* <div className="note-box">
            <Image
              src="/images/dashboard/notification_icon.svg": isMoreClicked
              alt="Notification"
              className="not-icon"
            />
            <span className="d-inline-block">12</span>
          </div> */}
          <NotificationDropdown />
          <div className="split-pipe"></div>
          {accessInfo?.setting &&
            <div className="setting" onClick={() => handleTab("setting")}>
              <Image src="/images/dashboard/setting_icon.svg" alt="Setting" />
            </div>
          }

          <ProfileBadge userDetails={currentProfileDetail} isSetting={accountDetailsShow} />
        </Col>
      </Row>
      <Offcanvas
        placement="start"
        show={navShow}
        onHide={() => handleNavClose()}
        className="side-menu"
      >
        <Offcanvas.Header className="justify-content-between">
          <Offcanvas.Title>
            <Image
              src="/images/dashboard/timelyze_new_logo_tab.svg"
              className="brand-logo"
            />
          </Offcanvas.Title>
          <Image
            src="/images/dashboard/side_menu_close.svg"
            className="side-close cursor-pointer"
            onClick={() => handleNavClose()}
          />
        </Offcanvas.Header>
        <Offcanvas.Body className="side-menu-body p-0 d-flex flex-column">
          <div className="d-flex flex-column">
            {accessInfo?.dashboard &&
              <div
                className={`d-flex flex-row menu-title-cont ${dashboardHover ||
                  (location.pathname.includes("dashboard") && !activeKey)
                  ? "active-tab"
                  : ""
                  }`}
                onClick={() => handleTab("dashboard")}
                onMouseEnter={() => handleMouseEnter('dashboard')}
                onMouseLeave={() => handleMouseLeave()}
              >
                <div>
                  <Image
                    src={

                      dashboardHover ||
                        (location.pathname.includes("dashboard") && !activeKey)
                        ? "/images/dashboard/dashboard_icon.svg"
                        : "/images/dashboard/fade_dashboard_icon.svg"
                    }
                    alt="Dashboard"
                  />
                </div>
                <p>Dashboard</p>
              </div>
            }
            {accessInfo?.projects &&
              <div
                className={`d-flex flex-row menu-title-cont `}
                onMouseEnter={() => handleMouseEnter('project')}
                onMouseLeave={() => handleMouseLeave()}
              >
                <div>
                  <Image
                    src={
                      projectHover
                        ? "/images/dashboard/project_icon.svg"
                        : "/images/dashboard/fade_project_icon.svg"
                    }
                    alt="project"
                  />
                </div>
                <p>Projects</p>
              </div>
            }
            {accessInfo?.manager &&
              <div
                className={`drop-side-mngr d-flex flex-row align-items-start menu-title-cont ${location.pathname.includes("managerList") ||
                  managerHover ||
                  activeKey === "0"
                  ? "active-tab"
                  : ""
                  }`}
                onClick={() => setIsManagerDropdown(!isManagerDropdown)}
                onMouseEnter={() => handleMouseEnter('manager')}
                onMouseLeave={() => handleMouseLeave()}
              >
                <Image
                  src={
                    managerHover ||
                      location.pathname.includes("managerList") ||
                      activeKey === "0"
                      ? "/images/dashboard/manager.svg"
                      : "/images/dashboard/fade_manager.svg"
                  }
                  alt="manager"
                  className=""
                />
                <Accordion
                  className={`drop-mngr  `}
                  activeKey={activeKey}
                  onSelect={handleSelect}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <p> Managers</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div
                        onClick={() =>
                          dispatch(
                            setManagerDropShow(!addManager?.managerDropShow)
                          )
                        }
                      >
                        <Accordion.Item
                          className={`${addManagerHover ? "active-tab" : ""}`}
                          onMouseEnter={() => handleMouseEnter('addManager')}
                          onMouseLeave={() => handleMouseLeave()}
                          eventKey="1"
                          onClick={() => {
                            handleNavClose();
                            location.pathname.includes("managerList")
                              ? setActiveKey("0")
                              : location.pathname.includes("roles")
                                ? setActiveKey("1")
                                : setActiveKey("");
                            dispatch(setModalShow(true));
                          }}
                        >
                          <Image
                            src={` /images/roles/${addManagerHover
                              ? "white_add_manager.svg"
                              : "inactive_add_manager.svg"
                              }`}
                            alt="Add Manager"
                          />
                          <p> Add Manager</p>
                        </Accordion.Item>
                        <Accordion.Item
                          className={`${location.pathname.includes("managerList") ||
                            managerListHover
                            ? "active-tab"
                            : ""
                            }`}
                          eventKey="2"
                          onMouseEnter={() => handleMouseEnter('managerList')}
                          onMouseLeave={() => handleMouseLeave()}
                          onClick={() => handleTab("managerList")}
                        >
                          <Image
                            src={`/images/roles/${managerListHover ||
                              location.pathname.includes("managerList")
                              ? "white_managerList.svg"
                              : "inactive_manager_list.svg"
                              }`}
                            alt="ManagerList"
                          />

                          <p> Manager List</p>
                        </Accordion.Item>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
            {accessInfo?.reports &&
              <div
                className={`d-flex flex-row menu-title-cont ${(location.pathname.includes("reports") && !activeKey)
                  ? "active-tab"
                  : ""
                  }`}
                onMouseEnter={() => handleMouseEnter('reports')}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => handleTab("reports")}
              >
                <div>
                  <Image
                    src={
                      (reportHover ||
                        selectedTab === "reports" ||
                        (location.pathname.includes("reports") && (!activeKey)))

                        ? "/images/dashboard/report_icon.svg"
                        : "/images/dashboard/fade_report_icon.svg"
                    }
                    alt="Reports"
                  />
                </div>
                <p>Reports</p>
              </div>
            }
            {accessInfo?.screenshot &&
              <div
                className={`d-flex flex-row menu-title-cont ${((location.pathname.includes("screenshots") && !activeKey) || screenshotHover)
                  ? "active-tab"
                  : ""
                  }`}
                onMouseEnter={() => handleMouseEnter('screenshots')}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => handleTab("screenshots")}
              >
                <div>
                  <Image
                    src={
                      (screenshotHover || (location.pathname.includes("screenshots") && !activeKey)
                      ) ? "/images/dashboard/screenshot_logo.svg"
                        : "/images/dashboard/fade_screenshot_icon.svg"
                    }
                    alt="Add Manager"
                  />
                </div>
                <p>Screenshots</p>
              </div>
            }

            {accessInfo?.roles &&
              <div
                className={`drop-side-mngr d-flex flex-row align-items-start menu-title-cont ${(moreHover ||
                  location.pathname.includes("roles") ||
                  activeKey === "1")
                  ? "active-tab"
                  : ""
                  }`}
                onMouseEnter={() => handleMouseEnter('more')}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() =>
                  setIsMoreClicked(
                    !isMoreClicked
                  )
                }
              >
                <Image
                  src={
                    (moreHover ||
                      location.pathname.includes("roles")) ||
                      activeKey === "1"
                      ? "/images/dashboard/three_dot.svg"
                      : "/images/dashboard/fade_three_dot.svg"
                  }
                  alt="manager"
                  className=""
                />
                <Accordion

                  className={`drop-mngr `}
                  activeKey={activeKey}
                  onSelect={handleSelect}
                >
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <p>More</p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <Accordion.Item
                          onMouseEnter={() => handleMouseEnter('role')}
                          onMouseLeave={() => handleMouseLeave()}
                          className={`${location.pathname.includes("roles") || roleHover
                            ? "active-tab"
                            : ""
                            }`}
                          eventKey="1"
                          onClick={() => handleTab("roles")}
                        >
                          <p> Roles</p>
                        </Accordion.Item>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
          </div>

          <div className="d-flex flex-column mt-auto">
            {accessInfo?.setting &&
              <div
                className={`d-flex flex-row menu-title-cont ${location.pathname.includes("settings") ? "active-tab" : ""
                  }`}
                onMouseEnter={() => handleMouseEnter('setting')}
                onMouseLeave={() => handleMouseLeave()}
                onClick={() => handleTab("setting")}
              >
                <div>
                  <Image
                    src={
                      settingHover ||
                        selectedTab === "settings" ||
                        location.pathname.includes("settings")
                        ? "/images/dashboard/setting_icon.svg"
                        : "/images/dashboard/fade_setting_icon.svg"
                    }
                    alt="Setting"
                  />
                </div>
                <p>Settings</p>
              </div>
            }
            <div
              className={`d-flex flex-row menu-title-cont ${selectedTab === "logout" ? "active-tab" : ""
                }`}
              onClick={() => handleLogout()}
            >
              <div>
                <Image
                  src="/images/dashboard/fade_logout_icon.svg"
                  alt="Add Manager"
                />
              </div>
              <p>Logout</p>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <>{props.children}</>
    </Container>
  );
};

export default NavbarLayout;
