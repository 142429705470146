import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
    startDate: Date,
    endDate: Date,
    filterType: string,
    empDeviceId: string,
    organization: string,
    department: string,
    filterChosen: string,
    employeeIds?: string[]
}

const initialState: InitialState = {
    startDate: new Date(),
    endDate: new Date(),
    filterType: "organization",
    empDeviceId: "",
    organization: "All Organizations",
    department: "",
    filterChosen: "today",
    employeeIds:[]
}

const dashboardSlice = createSlice({
    name: 'filterData',
    initialState,
    reducers: {
        setDate: (state, action: PayloadAction<{ startDate: Date, endDate: Date }>) => {
            state.startDate = action.payload.startDate
            state.endDate = action.payload.endDate
        },
        setFilterType: (state, action: PayloadAction<string>) => {
            state.filterType = action.payload
        },
        setDeviceID: (state, action: PayloadAction<string>) => {
            state.empDeviceId = action.payload
        },
        setOrganization: (state, action: PayloadAction<string>) => {
            state.organization = action.payload
        },
        setDepartment: (state, action: PayloadAction<string>) => {
            state.department = action.payload
        },
        setFilterChosen: (state, action: PayloadAction<string>) => {
            state.filterChosen = action.payload
        },
        setEmployeeIds: (state, action: PayloadAction<string[]>) => {
            state.employeeIds = action.payload
        }
    }
})

export const { setDate, setDeviceID, setFilterChosen, setFilterType,setOrganization, setDepartment,setEmployeeIds } = dashboardSlice.actions
export default dashboardSlice.reducer