import React, { useState, useRef, useEffect } from 'react';
import NavbarLayout from '../components/NavbarLayout';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import '../styles/reports.scss';
import { Select } from 'antd';
import Calendar from '../components/CustomComponents/DatePicker/Calendar';
import { commonGetService, commonPostservice } from '../utils/properties';
import { showToast } from '../features/Toaster/toastslice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccessInfo, AccessDetails } from './Dashboard';
import { RootState } from "../app/store";
import { hideToast } from "../features/Toaster/toastslice";

interface UserData {
    employee_id: number,
    employee_name: string
}

interface ReportData {
    id: string,
    report_type: string,
    label?: string
}
interface OptionObject {
    label: string,
    value: string,
    uId?: number | string,
    isChecked: boolean
}

interface ReportDetails {
    report_name: string,
    employee_name: string[],
    employee_id: number[],
    report_type: string,
    start_date: string,
    end_date: string,
    isDrop?: boolean
    dropUp?: boolean;
}

const Reports = () => {
    const [userDrop, setUserDrop] = useState<boolean>(false);
    const [reportDrop, setReportDrop] = useState<boolean>(false);
    const [userOptions, setUserOptions] = useState<OptionObject[]>([])
    const [reportOptions, setReportOptions] = useState<OptionObject[]>([])
    const [userNames, setUserNames] = useState<string[]>([]);
    const [reportNames, setReportNames] = useState<string[]>([]);
    const [reportName, setReportName] = useState<string>('');
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [reportDetails, setReportDetails] = useState<ReportDetails[]>([])
    const [dropOptions, setDropOptions] = useState<string[]>(['CSV', 'PDF', 'Excel']);
    const [isDrop, setIsDrop] = useState<boolean>(false);
    const [userError, setUserError] = useState<boolean>(false);
    const [reportTypeError, setReportTypeError] = useState<boolean>(false);
    const [reportNameError, setReportNameError] = useState<boolean>(false);
    const [fromDateError, setFromDateError] = useState<boolean>(false);
    const [toDateError, setToDateError] = useState<boolean>(false);
    const [isGenerate, setIsGenerate] = useState<boolean>(false);
    const [reportAll, setReportAll] = useState<boolean>(false)
    const [reportAllUser, setReportAllUser] = useState<boolean>(false)
    const [accessInfo, setAccessInfo] = useState<AccessInfo>({});
    const [isLoading,setIsLoading]=useState<boolean>(true);
    const { message, visible, status } = useAppSelector((state: RootState) => state.toast);
    const [dropdownPosition, setDropdownPosition] = useState<{ x: number; y: number } | null>(null);





    const listRef = useRef<(HTMLButtonElement | null)[][]>([]);
    const targetRef = useRef<(HTMLButtonElement | null)[]>([]);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useAppDispatch(); //Dispatch function
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                dispatch(hideToast());
            }, 3000); // Adjust the duration as needed

            return () => clearTimeout(timer);
        }
    }, [visible, dispatch]);


    const getReportData=async()=>{
       
        await getUsersList();
        await getReportNameList();
        await getReportList();
        
       
    }

    useEffect(() => {
        window.addEventListener('click', handleDropOpen);

        getReportData();

        return () => {
            window.removeEventListener('click', handleDropOpen);
        }
    }, [])

    useEffect(() => {

        const navigationEntry = performance.getEntriesByType("navigation")[0] as PerformanceNavigationTiming;

        if (location.state) {

            if (location?.state?.reportType) {
                setReportNames([location?.state?.reportType])
            }
            if (location?.state?.employeeIds) {
                if (location?.state?.Alluser) { setUserNames(['All', ...location?.state?.employeeIds]); setReportAllUser(true); } else { setUserNames(location?.state?.employeeIds) }
            }
            if (location?.state?.startDate) {
                setFromDate(new Date(location?.state?.startDate))
            }
            if (location?.state?.endDate) {
                setToDate(new Date(location?.state?.endDate))
            }
            location.state.reload = false;
        }
    }, [location.state,])
    useEffect(() => {
        const handleBeforeUnload = () => {
            navigate(location.pathname, { replace: true });
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [navigate, location.pathname])

    useEffect(() => {
        if (localStorage.getItem("roleDetails")) {
            let roleDetails = JSON.parse(localStorage.getItem("roleDetails") as string);
            let accessInfo: AccessInfo = {}
            roleDetails?.features?.reports?.features?.map((res: AccessDetails) => {
                accessInfo[res.name] = res?.status;
            })
            setAccessInfo(accessInfo);
        }
    }, [])




    console.log(isLoading);
    

    // useEffect(() => {
    //     if (reportDetails?.some(res => res.isDrop)) {
    //         let openedInd = reportDetails?.findIndex(res => res.isDrop);
    //         if (targetRef.current[openedInd] && dropdownRef.current) {
    //             const buttonRect = targetRef.current[openedInd]?.getBoundingClientRect() as DOMRect;
    //             const dropDownHeight = dropdownRef.current.offsetHeight;
    //             const spaceBelow = window.innerHeight - buttonRect.bottom;
    //             const spaceAbove = buttonRect?.top;

    //             if (spaceBelow < dropDownHeight && spaceAbove > dropDownHeight) {
    //                 setReportDetails(prevState => {
    //                     return prevState.map(res => {
    //                         if (res.isDrop) {
    //                             res.dropDirection = 'up';
    //                         }
    //                         return res;
    //                     })
    //                 })
    //             }
    //             else {
    //                 setReportDetails(prevState => {
    //                     return prevState.map(res => {
    //                         if (res.isDrop) {
    //                             res.dropDirection = 'down';
    //                         }
    //                         return res;
    //                     })
    //                 })
    //             }
    //         }
    //         window.scrollTo(0, window.scrollY); // Prevents auto page scrolling
    //     }

    // }, [reportDetails])

    const getUsersList = () => {
       
        commonGetService(`/api/1.0/report/users`).then(res => {
                
           
            if (res.status === 200) {
                                let userData = res.data?.map((user: UserData) => {
                    return {
                        label: user?.employee_name,
                        value: user?.employee_id.toString(),
                        isChecked: false
                    }
                })

                userData?.length && userData?.unshift({
                    label: 'All',
                    value: 'All',
                    isChecked: false
                })

                setUserOptions(userData)
            }
        })
    }

    const getReportNameList = () => {
        
        
        commonGetService(`/api/1.0/report/type`).then(res => {
           

            if (res.status === 200) {
                

                let reportData = res.data?.map((report: ReportData) => {
                    return {
                        label: report?.label,
                        value: report?.report_type,
                    }
                })

                reportData?.length && reportData?.unshift({
                    label: 'All',
                    value: 'All',
                })

                setReportOptions(reportData);
            }
        })
    }

    const getReportList = () => {
       setIsLoading(true);
        commonGetService(`/api/1.0/report`).then(res => {
       setIsLoading(false);

            if (res.status === 200) {

                let reportList = res.data?.map((report: ReportDetails) => {
                    listRef.current.push([]);
                    return {
                        report_name: report?.report_name,
                        employee_name: report?.employee_name,
                        employee_id: report?.employee_id,
                        report_type: report?.report_type,
                        start_date: moment(new Date(report?.start_date)).format("DD-MMM-YYYY"),
                        end_date: moment(new Date(report?.end_date)).format("DD-MMM-YYYY"),
                        isDrop: false,
                        dropDirection: 'down'
                    }
                })
                setReportDetails(reportList);
            }
        })
    }

    const handleDropOpen = (e: MouseEvent) => {
        if (targetRef && e.target instanceof Node && !targetRef.current?.some(res => res?.contains(e.target as Node))) {
            setReportDetails((prevState) =>
                prevState.map((res) => { return { ...res, isDrop: false } })
            );
        }
    }


    const handleDateChange = (val: Date | null, name: string) => {
        if (name === 'fromDate') {
            setFromDate(val);
            setFromDateError(false);
        }
        else {
            setToDate(val);
            setToDateError(false);
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>, ind: number, index: number) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            listRef.current[ind][index + 1]?.focus();
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            listRef.current[ind][index - 1]?.focus();
        } else if (e.key === 'Enter') {
            alert(`You selected Item ${index + 1}`);
        }
    };

    const handleValidate = () => {
        let isClear = true;
        if (userNames.length <= 0) {
            setUserError(true);
            isClear = false;
        }
        if (reportNames.length <= 0) {
            setReportTypeError(true);
            isClear = false;
        }
        if (reportName.trim() === "") {
            setReportNameError(true);
            isClear = false;
        }
        if (!fromDate) {
            setFromDateError(true);
            isClear = false;
        }
        if (!toDate) {
            setToDateError(true);
            isClear = false;
        }
        if (isClear) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleGenerateReport = () => {

        if (!handleValidate()) {
            setIsGenerate(false)
            return;
        }
        setIsGenerate(true);
        let params = {
            "empIds": userNames?.filter(res => res !== 'All')?.map(res => Number(res)),
            "report_type": reportNames?.filter(res => res !== 'All')?.map(res => res),
            "report_name": reportName,
            "start_date": moment(fromDate).format("YYYY-MM-DD"),
            "end_date": moment(toDate).format("YYYY-MM-DD")
        }
        setIsGenerate(true);
        commonPostservice(`/api/1.0/report/generate`, params).then(res => {
            setIsGenerate(false);
            if (res.status === 200) {
                dispatch(
                    showToast({
                        message: "Report Generated Successfully, See Below",
                        status: "success",
                        visible: true,
                    })
                );
                getReportList();
            }
            else if (res.status === 208) {
                dispatch(
                    showToast({
                        message: "Report Details Already Exist",
                        status: "retry",
                        visible: true,
                    }))
            }
            else {
                dispatch(
                    showToast({
                        message: "Something went wrong in API",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        })

    }

    const handleDownloadDropdown = (event: React.MouseEvent, i: number) => {
        event.stopPropagation();
        const { left, bottom } = event.currentTarget.getBoundingClientRect();
        const isOpen = !reportDetails[i]?.isDrop;
        setReportDetails(reportDetails.map((res, ind) => ({ ...res, isDrop: i === ind ? isOpen : false })));
        setDropdownPosition(isOpen ? { x: left, y: bottom } : null);
        if (isOpen) {
            const handleScroll = () => {
                setReportDetails(reportDetails.map(res => ({ ...res, isDrop: false })));
                setDropdownPosition(null);
                window.removeEventListener("scroll", handleScroll); // Remove listener after closing
            };
            window.addEventListener("scroll", handleScroll);
        }
    };


    const handleDownloadReport = (reportData: ReportDetails, format: string) => {
        let params = {
            "empIds": reportData?.employee_id,
            "report_type": [reportData?.report_type],
            "report_name": reportData?.report_name,
            "start_date": moment(reportData?.start_date).format("YYYY-MM-DD"),
            "end_date": moment(reportData?.end_date).format("YYYY-MM-DD"),
            "report_format": format === "PDF" ? "pdf" : format === "CSV" ? "csv" : "excel"
        }

        commonPostservice(`/api/1.0/report/download`, params).then(res => {
            if (res.status === 200) {
                let linkElement = document.createElement('a');
                linkElement.href = res?.data;
                document.body.appendChild(linkElement);
                linkElement.click();
                document.body.removeChild(linkElement);
            }
            else {
                dispatch(
                    showToast({
                        message: "Something went wrong in API",
                        status: "retry",
                        visible: true,
                    })
                );
            }
        })
    }
    const handleSelection = (selectedValues: string) => {
        let updatedNames = [...reportNames];

        setReportTypeError(false);
        if (selectedValues === 'All') {

            if (!updatedNames.includes('All')) {
                setReportNames(() => reportOptions?.map(res => res.value))
                setReportAll(true)
            }

        } else {

            if (updatedNames.includes(selectedValues)) {


                updatedNames = updatedNames.filter((res) => res !== selectedValues && res !== 'All')

                setReportAll(false)

            } else {
                updatedNames = [...updatedNames, selectedValues]
            }
            setReportNames(() => updatedNames)
            updatedNames.length === reportOptions?.length - 1 ? setReportAll(true) : setReportAll(false)

        }
    };

    const handleDeselection = (selectedValues: string) => {
        let updatedNames = [...reportNames];
        setReportTypeError(false);

        if (selectedValues === 'All') {

            if (updatedNames.includes('All')) {
                setReportNames(() => [])
                setReportAll(false)
            }

            if (updatedNames.length === reportOptions?.length - 1) {
                setReportNames(() => [])
                setReportAll(false)
            }

        } else {

            if (updatedNames.includes(selectedValues)) {
                updatedNames = updatedNames.filter((res) => res !== selectedValues && res !== 'All')

            } else {
                updatedNames = [...updatedNames, selectedValues]

                setReportAll(false)
            }
            setReportNames(() => updatedNames)

            updatedNames.length === reportOptions?.length - 1 ? setReportAll(true) : setReportAll(false)

        }
    };



    const handleUserSelection = (selectedValues: string) => {
        let updatedNames = [...userNames];
        setUserError(false);
        if (selectedValues === 'All') {

            if (!updatedNames.includes('All')) {
                setUserNames(() => userOptions?.map(res => res.value))
                setReportAllUser(true)
            }

        } else {

            if (updatedNames.includes(selectedValues)) {


                updatedNames = updatedNames.filter((res) => res !== selectedValues && res !== 'All')

                setReportAllUser(false)

            } else {
                updatedNames = [...updatedNames, selectedValues]
            }
            setUserNames(() => updatedNames)
            updatedNames.length === userOptions?.length - 1 ? setReportAllUser(true) : setReportAllUser(false)

        }
    };

    const handleUserDeselection = (selectedValues: string) => {
        let updatedNames = [...userNames];

        setUserError(false);
        if (selectedValues === 'All') {

            if (updatedNames.includes('All')) {
                setUserNames(() => [])
                setReportAllUser(false)
            }
            if (updatedNames.length === userOptions?.length - 1) {
                setUserNames(() => [])
                setReportAllUser(false)
            }

        } else {

            if (updatedNames.includes(selectedValues)) {
                updatedNames = updatedNames.filter((res) => res !== selectedValues && res !== 'All')

            } else {
                updatedNames = [...updatedNames, selectedValues]

                setReportAllUser(false)
            }
            setUserNames(() => updatedNames)

            updatedNames.length === userOptions?.length - 1 ? setReportAllUser(true) : setReportAllUser(false)

        }
    };

    return (<>
            <NavbarLayout>

        {isLoading ?
            <div className="d-flex justify-content-center align-items-center h-100 w-100 crt-loader">
            <img src="/images/loader/content_loader.gif" />
            {/* <Spinner animation="border" /> */}
        </div>
            : 
            
            <Container fluid className='w-100 report-out-container'>
                <div className='report-in-container'>
                    <Row className='m-0'>
                        <h4 className='mb-0 p-0'>Reports</h4>
                    </Row>
                    {accessInfo["Generate Report"] &&
                        <>
                            <Row className='user-containr' gap={{ lg: 4 }}>
                                <Col lg='6' className='cus-col'>
                                    <label htmlFor='user-select'>Select User</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select User, Department etc.,"
                                        // onChange={(val) => { handleChange(val, 'user') }}
                                        options={userOptions}
                                        optionRender={(option) => (
                                            <Form.Check
                                                type='checkbox'
                                                id={`${option.data.label}`}
                                                label={option.data.label}
                                                checked={userNames.includes(option.data.value) || reportAllUser}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    let updatedNames = [...userNames];
                                                    if (option.data.label === "All") {
                                                        // If "All" is unchecked, remove all selections
                                                        if (updatedNames.includes("All")) {
                                                            setUserNames([]);
                                                            setReportAllUser(false);
                                                        } else {
                                                            // Select all options
                                                            setUserNames(userOptions.map(res => res.value));
                                                            setReportAllUser(true);
                                                        }
                                                    } else {
                                                        if (updatedNames.includes(option.data.value)) {
                                                            // Remove the unchecked item
                                                            updatedNames = updatedNames.filter((res) => res !== option.data.value);

                                                            // If "All" was selected and any item is unchecked, remove "All"
                                                            if (updatedNames.includes("All")) {
                                                                updatedNames = updatedNames.filter((res) => res !== "All");
                                                                setReportAllUser(false);
                                                            }
                                                        } else {
                                                            // Add new item
                                                            updatedNames.push(option.data.value);
                                                            // If all options except "All" are selected, auto-select "All"
                                                            if (updatedNames.length === userOptions.length - 1) {
                                                                setReportAllUser(true);
                                                            } else {
                                                                setReportAllUser(false);
                                                            }
                                                        }
                                                        if (updatedNames.length === userOptions.length - 1) {
                                                            setReportAllUser(true);
                                                        } else {
                                                            setReportAllUser(false);
                                                        }
                                                        setUserNames(updatedNames);
                                                    }
                                                }}
                                            />
                                        )}
                                        value={reportAllUser ? ['All'] : userNames}
                                        popupClassName='mod-drop'
                                        className={`custom-dropdown ${userDrop ? 'drop-open' : ''}`}
                                        showSearch={false}
                                        suffixIcon={<img src="/images/dashboard/down_arrow.svg" />}
                                        onDropdownVisibleChange={(isOpen) => { setUserDrop(isOpen) }}
                                        id='user-select'
                                        onSelect={(val) => { handleUserSelection(val) }}
                                        onDeselect={(val) => { handleUserDeselection(val) }}
                                    />
                                    {userError && <p className='err-txt'>Select the User</p>}
                                </Col>
                                <Col lg='6' className='cus-col'>
                                    <label htmlFor='report-select'>Report Type</label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Select Report Type"
                                        options={reportOptions}
                                        optionRender={(option) => (
                                            <Form.Check
                                                type='checkbox'
                                                key={`${option.data.uId}-option`}
                                                id={`${option.data.label}`}
                                                label={option.data.label}
                                                checked={reportNames.includes(option.data.value) || reportAll}
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    let updatedNames = [...reportNames];

                                                    if (option.data.label === "All") {
                                                        // If "All" is unchecked, remove all selections
                                                        if (updatedNames.includes("All")) {
                                                            setReportNames([]);
                                                            setReportAll(false);
                                                        } else {
                                                            // Select all options
                                                            setReportNames(reportOptions.map(res => res.value));
                                                            setReportAll(true);
                                                        }
                                                    } else {
                                                        if (updatedNames.includes(option.data.value)) {
                                                            // Remove the unchecked item
                                                            updatedNames = updatedNames.filter((res) => res !== option.data.value);
                                                            // If "All" was selected and any item is unchecked, remove "All"

                                                            if (updatedNames.includes("All")) {
                                                                updatedNames = updatedNames.filter((res) => res !== "All");
                                                                setReportAll(false);
                                                            }
                                                        } else {
                                                            // Add new item
                                                            updatedNames.push(option.data.value);
                                                            // If all options except "All" are selected, auto-select "All"

                                                        }
                                                        if (updatedNames.length === reportOptions.length - 1) {
                                                            updatedNames.push("All");
                                                            setReportAll(true);
                                                        } else {
                                                            setReportAll(false);
                                                        }
                                                        setReportNames(updatedNames);
                                                    }
                                                }}
                                            />
                                        )}
                                        value={reportAll ? ['All'] : reportNames}
                                        popupClassName='mod-drop'
                                        className={`custom-dropdown ${reportDrop ? 'drop-open' : ''}`}
                                        showSearch={false}
                                        suffixIcon={<img src="/images/dashboard/down_arrow.svg" />}
                                        onDropdownVisibleChange={(isOpen) => { setReportDrop(isOpen) }}
                                        id='report-select'
                                        defaultActiveFirstOption={true}
                                        onSelect={(val) => { handleSelection(val) }}
                                        onDeselect={(val) => { handleDeselection(val) }}
                                    />
                                    {reportTypeError && <p className='err-txt'>Select the Report</p>}
                                </Col>
                            </Row>
                            <Row className='report-containr' gap={{ lg: 4 }}>
                                <Col lg='6' className='cus-col'>
                                    <Form.Label htmlFor='report-name'>Report Name</Form.Label>
                                    <Form.Control type='text' name='reportName' id='report-name' placeholder='Enter Report Name' value={reportName}
                                        onChange={(e) => { setReportName(e.target.value); setReportNameError(false); }} />
                                    {reportNameError && <p className='err-txt'>Enter the report name</p>}
                                </Col>
                                <Col lg='6' className='d-flex flex-column cus-col mt-auto'>
                                    <div>
                                        <h6 className='label-text mb-0'>Report Date Range</h6>
                                    </div>
                                    <div className='d-flex flex-row mt-2 w-100 justify-content-between align-items-center'>
                                        <Calendar
                                            selectedDate={fromDate}
                                            handleDateChange={handleDateChange}
                                            calTitle='fromDate'
                                            isError={fromDateError}
                                        />
                                        <div className='to-head'>To</div>
                                        <Calendar
                                            selectedDate={toDate}
                                            handleDateChange={handleDateChange}
                                            calTitle='toDate'
                                            isError={toDateError}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='gen-button'>
                                <Col>
                                    <Button onClick={() => handleGenerateReport()} disabled={isGenerate || visible}>Generate report</Button>
                                </Col>
                            </Row>
                        </>
                    }


                    <Row className='table-header'>
                        <Col>
                            <h6>Recently Generated Reports <span>{`(${reportDetails?.length} Report)`}</span></h6>
                        </Col>
                    </Row>
                    <Row className='table-container styled-scroll-hor'>
                        <Col>
                            <table >
                                <thead>
                                    <tr>
                                        <th>Report Name</th>
                                        <th>User</th>
                                        <th>Report Type</th>
                                        <th>From Date</th>
                                        <th>To Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportDetails.length ?
                                        reportDetails?.map((res, ind) => (
                                            <tr key={`${ind} ${res?.report_name}`}>
                                                <td><div>{res?.report_name}</div></td>
                                                <td>
                                                    <div className='d-flex flex-row report-type'>
                                                        {res?.employee_name?.filter((_, i) => i < 2)?.map((reportName, ind) => (
                                                            <div key={ind}>{reportName}</div>
                                                        ))}
                                                        {res?.employee_name.length > 2 && <div key={"+1"}>{`+${res?.employee_name.length - 2}`}</div>}
                                                    </div>
                                                </td>
                                                <td><div>{res?.report_type}</div></td>
                                                <td><div>{res?.start_date}</div></td>
                                                <td><div>{res?.end_date}</div></td>
                                                <td>
                                                    <div className='format-cont'>
                                                        <div className='d-flex flex-row justify-content-start'>
                                                            <Button><Image src='images/reports/eye_icon.svg' /></Button>
                                                            <Button className='ms-2' ref={(el: HTMLButtonElement) => (targetRef.current[ind] = el)} onClick={(event) => {
                                                                handleDownloadDropdown(event, ind);
                                                            }}>
                                                                <Image src='images/reports/download_icon.svg' />
                                                            </Button>
                                                        </div>

                                                        {res?.isDrop && dropdownPosition && (
                                                            <div className='cus-drop-menu'
                                                                style={{
                                                                    position: "fixed",
                                                                    left: `${dropdownPosition.x - 120}px`,
                                                                    top: `${dropdownPosition.y - 165}px`,
                                                                    zIndex: 1000,
                                                                }}>
                                                                <div className='d-flex flex-column'>
                                                                    {dropOptions?.map((item, index) => (
                                                                        <Button
                                                                            key={index}
                                                                            ref={(el: HTMLButtonElement) => (listRef.current[ind][index] = el)} // Keep a reference to each item
                                                                            onKeyDown={(e) => handleKeyDown(e, ind, index)}
                                                                            autoFocus={index === 0}
                                                                            onClick={() => handleDownloadReport(res, item)}
                                                                        >
                                                                            <Image src='images/reports/csv_icon.svg' alt='csv' />
                                                                            <span>{item}</span>
                                                                        </Button>
                                                                    ))}
                                                                </div>
                                                            </div>)
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td></td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </Container>}
        </NavbarLayout >
        
        </>
    );
};

export default Reports;