import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import "../../styles/Managers/roles.scss";
import RolesListTable from "./RolesListTable";
import { ADDRGETNETWORKPARAMS } from "dns/promises";
import { useNavigate } from "react-router-dom";
import { RolesData } from "../../pages/ManagerList";
import { roleContext } from "../../pages/roles";


const Roleslist = () => {
  const Navigate = useNavigate();
  const rolesList: RolesData[] | null = useContext(roleContext);

  const addRole = () => {
    Navigate("/addroles");
  };
  return (
    <div className="roles-wrapper">
      {!rolesList?.length ? (
        <div className="no-roles-wrapper">
          <Image src="/images/roles/no_roles.svg" alt="No roles Found" />
          <p className="heading">No roles found</p>
          <p className="sentences">
            No Role Created ! It looks like you haven&apos;t added any roles yet.
            Roles help you manage permissions and define what users can and
            can&apos;t do. Add Role
          </p>
          <button className="add_role_button" onClick={addRole}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 6L12 18"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M18 12L6 12"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>{" "}
            Add Role
          </button>
        </div>
      ) : (
        <RolesListTable />
      )}
    </div>
  );
};

export default Roleslist;
