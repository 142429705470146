import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { userDetails } from "../../NavbarLayout";
import { useNavigate } from "react-router";

interface ProfileBadgeProps {
  userDetails: userDetails;
  isSetting: boolean
}

const ProfileBadge: React.FC<ProfileBadgeProps> = ({ userDetails, isSetting }) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const { name, picture, employee_id, role } = userDetails;
  const targetRef = useRef<HTMLDivElement | null>(null);

  const [profilePic, setProfilePic] = useState<string>("");



  const navigate = useNavigate()
  useEffect(() => {

    setProfilePic((prevState) => {

      prevState = ""
      return userDetails.picture;
    }
    )
  }, [userDetails])

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        targetRef.current &&
        e.target instanceof Node &&
        !targetRef.current.contains(e.target)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      setShowDropdown(false);
    };
    // Add scroll event listener
    window.addEventListener("wheel", handleScroll, { passive: true });
    window.addEventListener("scroll", handleScroll, { passive: true });

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);



  const handleLogout = () => {
    document.cookie =
      "X-XSRF-TOKEN = null;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    window.location.href = "/";
    // navigate("/")
  };




  return (
    <>
      <div ref={targetRef} className="custom-profile-badge">
        <div
          className="profile-badge d-flex justify-content-betweeen align-items-center cursor-pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className={profilePic ? "pro-pic" : "pro-pic fill-color-avatar"}>
            {profilePic ? (
              <Image src={profilePic} alt="Profile" />
            ) : (
              <span>{name.charAt(0).toUpperCase()}</span>
            )}
          </div>
          <div>
            <Image src="/images/dashboard/down_arrow.svg" alt="Down arrow" />
          </div>
        </div>

        {showDropdown && (
          <div className="badge-dropdown">
            <p>Profile</p>
            <div className="user-block">
              <div
                className={profilePic ? "pro-pic" : "pro-pic fill-color-avatar"}
              >
                {profilePic ? (
                  <Image src={profilePic} alt="Profile" />
                ) : (
                  <span>{name.charAt(0).toUpperCase()}</span>
                )}
                <div className="cam-icon-holder cursor-pointer">
                  <Image src="/images/dashboard/cam_icon.svg" alt="Profile" />
                </div>
              </div>
              <p title={name} className="user-name">{name}</p>
              <p className="user-id" title={role}>
                {role}
              </p>
            </div>

            <div className="badge-info cursor-pointer">
              {isSetting &&
                <div className="d-flex align-items-center">
                  <span className="profile-avatar-style">
                    <Image
                      src="/images/dashboard/add-friend 1.svg"
                      alt="account details"
                      onClick={() => navigate("/settings")}
                    />
                  </span>
                  <span onClick={() => navigate("/settings")}>Account Details</span>
                </div>
              }
              <div
                className={`d-flex align-items-center ${!isSetting ? "py-3" : ""}`}
                onClick={() => handleLogout()}
              >
                <span className="profile-avatar-style">
                  <Image src="/images/dashboard/power.svg" alt="sign out" />
                </span>
                <span>Sign Out</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileBadge;
